import React, { useContext } from 'react'
import { Snackbar as MaterialSnackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import SnackbarContext from '~Contexts/Snackbar.context'

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Snackbar = () => {
  const { closeSnackbar, message, open, severity } = useContext(SnackbarContext)

  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      open={open}
    >
      <Alert onClose={closeSnackbar} severity={severity}>
        {message}
      </Alert>
    </MaterialSnackbar>
  )
}

export default Snackbar
