import { useEffect } from 'react'

const ImportScript = (
  resourceUrl: string,
  position: HTMLElement | null,
  id: string
) => {
  useEffect(() => {
    if (!position) {
      return
    }

    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('id', id)
    script.src = resourceUrl
    script.async = true
    position.appendChild(script)
    return () => {
      position.removeChild(script)
    }
  }, [id, position, resourceUrl])
}
export default ImportScript
