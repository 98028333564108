import { AxiosResponse } from 'axios'
import { FormikHelpers } from 'formik'

import routes, { defaultSitePath } from '~Routes/routes'
import ApiErrorCodeType from '~Types/ApiErrorCode.type'
import CeremonyType from '~Types/Ceremony.type'

export const getTranslationOfCeremonyType = (ceremonyType: CeremonyType) => {
  switch (ceremonyType) {
    case CeremonyType.BURIAL:
      return 'Entierro'
    case CeremonyType.CREMATION:
      return 'Incineración'
    case CeremonyType.OTHER:
      return 'Ceremonia adicional'
    case CeremonyType.WAKE:
      return 'Velatorio'
  }
}

export const getCustomHeaders = () => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')
  headers.append('Origin', process.env.REACT_APP_API_DOMAIN!)

  return headers
}

export const getSiteUrl = (
  nameUrl: string | undefined,
  codeUrl: string | undefined
): string | null => {
  if (!nameUrl || !codeUrl) {
    return null
  }

  let siteUrl = defaultSitePath
  siteUrl = siteUrl.replace(':name', nameUrl)
  siteUrl = siteUrl.replace(':code', codeUrl)
  return siteUrl
}

export const getVisitorSiteUrl = (siteUrl: string): string | null => {
  if (!siteUrl) {
    return null
  }
  return process.env.REACT_APP_DOMAIN + siteUrl + '/vista'
}

export const handleFormikCustomErrorsForm = (
  actions: FormikHelpers<any>,
  errors: any[]
) => {
  for (const error of errors) {
    if (error.nestedErrors && error.nestedErrors.length) {
      for (const nestedError of error.nestedErrors) {
        if (nestedError.param && nestedError.msg) {
          actions.setFieldError(nestedError.param, error.msg)
        }
      }
    } else if (error.param && error.msg) {
      actions.setFieldError(error.param, error.msg)
    }
  }
}

export const handlePageNotFoundRedirect = (history: any) => {
  const route = routes.pageNotFound.path
  return history.push(route)
}

export const handlePageNotPublishedRedirect = (history: any) => {
  const route = routes.pageNotFound.path
  return history.push(route)
}

export const handleRedirect = (
  history: any,
  response: AxiosResponse<any>,
  nameUrl: string,
  codeUrl: string
) => {
  const errors = response.data.errors

  for (const error of errors) {
    switch (error.code) {
      case ApiErrorCodeType.NOT_FOUND:
      case ApiErrorCodeType.NOT_ACTIVATED:
        handlePageNotFoundRedirect(history)
        return true
      case ApiErrorCodeType.SESSION_EXPIRED:
      case ApiErrorCodeType.UNAUTHORIZED:
      case ApiErrorCodeType.UNAUTHORIZED_MANAGER:
        const url = window.location.href
        const isEdition = url.search('/edicion')
        if (isEdition !== -1) {
          handleSessionExpiredManagerRedirect(history, nameUrl, codeUrl)
        } else {
          handleSessionExpiredVisitorRedirect(history, nameUrl, codeUrl)
        }
        return true
    }
  }

  return false
}

export const handleSessionExpiredManagerRedirect = (
  history: any,
  nameUrl: string,
  codeUrl: string
) => {
  let route = routes.accessManager.path
  route = route.replace(':name', nameUrl)
  route = route.replace(':code', codeUrl)
  return history.push(route)
}

export const handleSessionExpiredVisitorRedirect = (
  history: any,
  nameUrl: string,
  codeUrl: string
) => {
  let route = routes.accessVisitor.path
  route = route.replace(':name', nameUrl)
  route = route.replace(':code', codeUrl)
  return history.push(route)
}

export const openWhatsAppToShareMessage = (message: string | null) => {
  if (message) {
    window.open('https://wa.me/?text=' + encodeURIComponent(message), '_blank')
  }
}
