import React from 'react'
import styled from 'styled-components'

interface ColorBoxProps {
  color: string
  isSelected: boolean
  onClick: (color: string) => void
}

interface SColorBoxContainerProps {
  isSelected: boolean
}

interface SColorBoxProps {
  color: string
}

const ColorBox = ({ color, isSelected, onClick }: ColorBoxProps) => {
  return (
    <SColorBoxContainer isSelected={isSelected}>
      <SColorBox onClick={() => onClick(color)} color={color} />
    </SColorBoxContainer>
  )
}

const SColorBox = styled.div<SColorBoxProps>`
  background-color: ${(props: SColorBoxProps) => props.color};
  height: 38px;
  width: 38px;
`

const SColorBoxContainer = styled.div<SColorBoxContainerProps>`
  border: ${(props: SColorBoxContainerProps) =>
    props.isSelected ? '2px solid #979797' : ''};
  padding: 5px;
`

export default ColorBox
