import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import GoBackButton from '~Components/GoBackButton'

const CookiesPolicy = (props: RouteComponentProps) => {
  return (
    <Grid container direction="row">
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <Grid container direction="column">
          <SGoBackButtonContainer item xs={12}>
            <GoBackButton goBack={props.history.goBack} />
          </SGoBackButtonContainer>
          <STitleContainer item xs={12}>
            Política de Cookies
          </STitleContainer>
          <STextContainer item xs={12}>
            <div className="section">
              1. ¿Qué son las cookies y por qué las utilizamos en los sitios web
              Preventiva Seguros?
            </div>
            <div className="text">
              Una cookie es un fichero que se descarga en tu dispositivo al
              acceder a determinadas páginas web para almacenar y recuperar
              información sobre la navegación que se efectúa desde dicho equipo.
            </div>
            <div className="text">
              Las cookies permiten en un sitio web, entre otras cosas, almacenar
              y recuperar información sobre los hábitos y configuración del
              usuario. En Preventiva Seguros las utilizamos para personalizar la
              experiencia dentro de nuestras páginas webs, identificar y
              recordar las preferencias del usuario, incluyendo el registro de
              cada uno y facilitar la navegación. Destacamos que el uso de
              cookies en nuestros sitios web no proporciona datos personales del
              usuario, por lo que permanece anónimo para nosotros.
            </div>
            <div className="text">
              Cada usuario podrá configurar su navegador para no aceptar el uso
              de cookies, en cuyo caso la personalización de la experiencia no
              se aplicaría aunque sí podría seguir accediendo a los contenidos
              de nuestras webs con normalidad.
            </div>
            <div className="text">
              Para más información acerca de qué son las cookies y cómo
              funcionan, recomendamos leer el contenido en la página de
              Wikipedia sobre las Cookies.
            </div>

            <div className="section">
              2. ¿Cómo se puede modificar la configuración de las cookies?
            </div>
            <div className="text">
              El Usuario puede modificar la configuración o retirar sus
              consentimientos en cualquier momento aquí [INSERTAR UN ENLACE AL
              COMPONENTE DE COOKIES].
            </div>

            <div className="section">
              3. ¿Qué tipo de cookies utilizan los sitios web de Preventiva
              Seguros?
            </div>
            <div className="text">
              Las Cookies, en función de su permanencia, pueden dividirse en
              Cookies de sesión o permanentes. Las primeras expiran cuando el
              Usuario cierra el navegador. Las segundas expiran en función
              cuando se cumpla el objetivo para el que sirven (por ejemplo, para
              que el Usuario se mantenga identificado en las webs de Preventiva
              Seguros) o bien cuando se borran manualmente.
            </div>
            <div className="text">
              Adicionalmente, en función de su finalidad, las Cookies puedes
              clasificarse de la siguiente forma:
            </div>
            <div className="text">
              COOKIES NECESARIAS: Las cookies necesarias ayudan a hacer una
              página web utilizable activando funciones básicas como la
              navegación en la página y el acceso a áreas seguras de la página
              web. La página web no puede funcionar adecuadamente sin estas
              cookies.
            </div>
            <div className="text">
              COOKIES DE PREFERENCIAS: Las cookies de preferencias permiten a la
              página web recordar información que cambia la forma en que la
              página se comporta o el aspecto que tiene, como su idioma
              preferido o la región en la que usted se encuentra.
            </div>
            <div className="text">
              COOKIES DE ESTADÍSTICA: Las cookies estadísticas ayudan a los
              propietarios de páginas web a comprender cómo interactúan los
              visitantes con las páginas web reuniendo y proporcionando
              información de forma anónima.
            </div>
            <div className="text">
              A modo de ejemplo, una herramienta de un proveedor externo (Google
              Analytics y similares que podrán añadirse a este listado en caso
              de que varíen en relación con los actuales) genera una Cookie
              analítica en el ordenador del Usuario. Esta Cookie que sólo se
              genera en la visita, servirá en próximas visitas a los servicios
              de Preventiva Seguros para identificar de forma anónima al
              visitante. Los objetivos principales que se persiguen son:
            </div>

            <div className="text">
              <ul>
                <li>
                  Permitir la identificación anónima de los Usuarios navegantes
                  a través de la "Cookie" (identificando navegadores y
                  dispositivos, no a las personas) y por lo tanto la
                  contabilización aproximada del número de visitantes y la
                  tendencia en el tiempo.
                </li>
                <li>
                  Identificar de forma anónima los contenidos más visitados y
                  por lo tanto más atractivos para los Usuarios
                </li>
                <li>
                  Saber si el Usuario que está accediendo es nuevo o repite
                  visita.
                </li>
              </ul>
            </div>

            <div className="text">
              Importante: Salvo que el Usuario decida registrarse en un alguna
              web de Preventiva Seguros, la "Cookie" nunca irá asociada a ningún
              dato de carácter personal que pueda identificarle. Dichas Cookies
              sólo serán utilizadas con propósitos estadísticos que ayuden a la
              optimización de la experiencia de los Usuarios en el sitio.
            </div>

            <div className="text">
              Más información sobre la política de privacidad de estas
              herramientas de analítica en:
            </div>
            <div className="text">
              <ul>
                <li>
                  Google Analytics
                  (http://www.google.es/policies/technologies/ads/)
                </li>
                <li>
                  Google Ads (http://www.google.es/policies/technologies/ads/)
                </li>
              </ul>
            </div>

            <div className="text">
              COOKIES DE MARKETING: Las cookies de marketing se utilizan para
              rastrear a los visitantes en las páginas web. La intención es
              mostrar anuncios relevantes y atractivos para el usuario
              individual, y por lo tanto, más valiosos para los editores y
              terceros anunciantes.
            </div>
            <div className="text">
              Adicionalmente, algunos Servicios pueden utilizar conectores con
              redes sociales tales como Facebook, YouTube, Instagram o Twitter.
              Cuando el Usuario se registra en un Servicio con credenciales de
              una red social, autoriza a la red social a guardar una Cookie
              persistente que recuerda la identidad y garantiza el acceso a los
              Servicios hasta que expire. El Usuario podrá borrar esta Cookie y
              revocar el acceso a los Servicios mediante redes sociales
              actualizando las preferencias en la red social que especifique.
            </div>

            <div className="section">
              4. ¿Cómo deshabilitar las cookies en el navegador?
            </div>
            <div className="text">
              La mayoría de navegadores actualmente permiten al usuario
              configurar si desean aceptar cookies y cuáles de ellas. Estos
              ajustes normalmente se encuentran en las "opciones" o
              "Preferencias" del menú de cada navegador.
            </div>
            <div className="text">
              Estas son las instrucciones para configurar las cookies en los
              principales navegadores:
            </div>
            <div className="text">
              Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad
              -> Configuración de contenido.
            </div>
            <div className="text">
              Firefox: Herramientas -> Opciones -> Privacidad -> Historial ->
              Configuración Personalizada.
            </div>
            <div className="text">
              Internet Explorer: Herramientas -> Opciones de Internet ->
              Privacidad -> Configuración.
            </div>
            <div className="text">Safari: Preferencias -> Seguridad.</div>
            <div className="text">
              Puede consultar más información en el Soporte o Ayuda de cada uno
              de los navegadores.
            </div>
          </STextContainer>
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  )
}

const SGoBackButtonContainer = styled(Grid)`
  margin-top: 80px;
`

const STextContainer = styled(Grid)`
  margin-bottom: 182px;
  margin-top: 32px;

  .section {
    font-family: 'Roboto Medium';
    font-size: 19px;
    font-weight: 300;
    margin-top: 40px;
  }

  .text {
    font-family: 'Roboto Light';
    font-size: 15px;
    font-weight: 300;
    margin-top: 20px;
  }
`

const STitleContainer = styled(Grid)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Light';
  font-size: 42px;
  font-weight: 300;
  margin-top: 32px;
  letter-spacing: 0.09px;
  line-height: 56px;
  text-align: center;
`

export default CookiesPolicy
