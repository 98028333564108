import { createMuiTheme } from '@material-ui/core'
import { esES } from '@material-ui/core/locale'

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: '#FFF1D0',
        main: '#2196F3',
      },
      secondary: {
        main: '#2E4052',
      },
    },
  },
  esES
)

export default theme
