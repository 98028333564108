import React from 'react'
import styled from 'styled-components'

interface TitleProps {
  children: React.ReactNode
}

const STitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto light';
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.09px;
  line-height: 39.78px;
`

const Title = ({ children }: TitleProps) => (
  <STitle>{children}</STitle>
)

export default Title
