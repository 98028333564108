import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'

import UserConfigContext from '~Contexts/UserConfig.context'
import routes from '~Routes/routes'
import Logo from '~Screens/Access/components/Logo'
import { getVisitorSiteUrl, openWhatsAppToShareMessage } from '~Utils/index'
import Ceremony from './Ceremony'

interface InformationProps {
  decease: any
}
const Information = ({ decease }: InformationProps) => {
  const history = useHistory()
  const { name: nameUrl, code: codeUrl } = useParams()
  const { isManager } = useContext(UserConfigContext)

  return (
    <SGridContainer container direction="column">
      <Grid item md={2} />
      <Grid container item xs={12} md={8} direction="row" justify="center">
        {decease.site && decease.site.ceremonies.length
          ? decease.site.ceremonies.map((data: any, key: number) => {
              return (
                <SSectionContainer
                  item
                  xs={12}
                  md={6}
                  key={data.type}
                  style={{ marginTop: 16 }}
                >
                  <Ceremony ceremony={data} color={decease.site.color} />
                </SSectionContainer>
              )
            })
          : ''}
      </Grid>
      <Grid item md={2} />

      <SDeveloperByContainer item xs={12} md={4} style={{ marginBottom: 150 }}>
        <SCard
          alignItems="center"
          container
          direction="column"
          justify="center"
          style={{ marginBottom: 157, marginTop: 16 }}
        >
          <SDeveloperByTitle item xs={12} md={6}>
            Desarrollado por
          </SDeveloperByTitle>

          <Grid item xs={12} md={6}>
            <SLogoContainer>
              <Logo />
            </SLogoContainer>
          </Grid>
        </SCard>
      </SDeveloperByContainer>

      {isManager ? (
        <SFixedButtonContainer>
          <Button
            color="primary"
            endIcon={<CreateIcon />}
            onClick={() => {
              if (nameUrl && codeUrl) {
                let route = routes.edition.path
                route = route.replace(':name', nameUrl)
                route = route.replace(':code', codeUrl)
                history.push(route)
              }
            }}
            type="submit"
            variant="contained"
          >
            VOLVER AL EDITOR
          </Button>
        </SFixedButtonContainer>
      ) : (
        <SFixedButtonContainer>
          <SShareToWhatsAppButton
            onClick={() => {
              openWhatsAppToShareMessage(
                decease.site.whatsAppMessageToShare +
                  '\n\n' +
                  getVisitorSiteUrl(decease.site.url)
              )
            }}
          >
            <SWhatsAppIcon />
          </SShareToWhatsAppButton>
        </SFixedButtonContainer>
      )}
    </SGridContainer>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`

const SDeveloperByContainer = styled(Grid)`
  max-height: 71px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`

const SDeveloperByTitle = styled(Grid)`
  color: #222e5e;
  font-family: 'Roboto Light';
  font-size: 10px;
  letter-spacing: 0.06px;
  line-height: 24px;
  padding-top: 10px;
  text-align: center;
`

const SFixedButtonContainer = styled.div`
  bottom: 37px;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
`

const SGridContainer = styled(Grid)`
  align-items: center;
  background-color: #f5f5f5;
  flex: 1;
`

const SLogoContainer = styled.div`
  display: block;
  height: 23px;
  margin: auto auto 10px;
  width: 150px;
`

const SSectionContainer = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  max-width: 500px;
  width: 100%;
`

const SShareToWhatsAppButton = styled.button`
  align-items: center;
  background-color: #25d366;
  box-shadow: inset 0 0 13px 0 rgba(253, 255, 236, 0.5),
    0 0 8px 0 rgba(86, 84, 84, 0.5);
  border: none;
  border-radius: 100%;
  color: white;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: auto;
  width: 80px;

  :focus {
    border: none;
    outline: none;
  }
`

const SWhatsAppIcon = styled(WhatsAppIcon)`
  cursor: pointer;
  height: 40px;
  margin-bottom: 5px;
  width: 40px;
`

export default Information
