import moment from 'moment'
import 'moment/locale/es'
import React from 'react'
import styled from 'styled-components'
import MomentUtils from '@date-io/moment'
import {
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TextField,
  TextFieldProps,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import ConfirmationDialog from '~Components/ConfirmationDialog'
import GoogleMapsPlace from '~Components/GoogleMapsPlace'
import CardTitle from '~Screens/Edition/components/CardTitle'
import CeremonyType from '~Types/Ceremony.type'
import { getTranslationOfCeremonyType } from '~Utils/index'

interface CeremonyRenderProps {
  addressAliasesError: string
  addressAliasesValue: string
  addressError: string
  addressValue: string
  dateValue: string
  datetimeAtValue: string
  datetimeToValue: string
  extraInformationError: string
  extraInformationValue: string
  index: any
  handleChange: any
  removeThisCeremony: any
  setFieldValue: any
  titleError: string
  titleValue: string
  type: CeremonyType
  updateCeremonyValues: any
}

const KeyboardPickerFieldComponent = (props: TextFieldProps) => {
  return <TextField {...props} disabled={true} />
}

const CeremonyRender = ({
  addressAliasesError,
  addressAliasesValue,
  addressError,
  addressValue,
  dateValue,
  datetimeAtValue,
  datetimeToValue,
  extraInformationError,
  extraInformationValue,
  index,
  handleChange,
  removeThisCeremony,
  setFieldValue,
  titleError,
  titleValue,
  type,
  updateCeremonyValues,
}: CeremonyRenderProps) => {
  const [
    openConfirmDialogToDelete,
    setOpenConfirmDialogToDelete,
  ] = React.useState(false)

  const setAddressValue = (addressValue: string | '') => {
    handleChange('decease.site.ceremonies[' + index + '].address')
    updateCeremonyValues(index, 'address', addressValue)
  }

  const setLat = (lat: number | null) => {
    handleChange('decease.site.ceremonies[' + index + '].lat')
    updateCeremonyValues(index, 'lat', lat)
  }

  const setLng = (lng: number | null) => {
    handleChange('decease.site.ceremonies[' + index + '].long')
    updateCeremonyValues(index, 'long', lng)
  }

  return (
    <>
      <SCard container direction="column">
        <ExpansionPanel defaultExpanded={true}>
          <SExpansionPanelSummary
            aria-controls="panel1a-content"
            expandIcon={<ExpandMoreIcon />}
          >
            <CardTitle>
              {type === CeremonyType.OTHER && titleValue
                ? titleValue
                : getTranslationOfCeremonyType(type)}
            </CardTitle>
          </SExpansionPanelSummary>
          <SExpansionPanelDetails>
            <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
              <>
                {type === CeremonyType.OTHER && (
                  <Grid item xs={12} style={{ marginTop: 10 }}>
                    <TextField
                      autoComplete="title"
                      error={!!titleError}
                      helperText={titleError}
                      label="Título"
                      name="title"
                      onChange={(event) => {
                        handleChange(
                          'decease.site.ceremonies[' + index + '].title'
                        )
                        updateCeremonyValues(index, 'title', event.target.value)
                      }}
                      style={{ width: '100%' }}
                      type="string"
                      value={titleValue}
                      variant="outlined"
                    />
                  </Grid>
                )}

                <Grid item xs={12} style={{ marginTop: 26 }}>
                  <TextField
                    autoComplete="extraInformation"
                    error={!!extraInformationError}
                    helperText={extraInformationError}
                    label="Información adicional"
                    name="extraInformation"
                    onChange={(event) => {
                      handleChange(
                        'decease.site.ceremonies[' +
                          index +
                          '].extraInformation'
                      )
                      updateCeremonyValues(
                        index,
                        'extraInformation',
                        event.target.value
                      )
                    }}
                    style={{ width: '100%' }}
                    type="string"
                    value={extraInformationValue}
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ marginTop: 16, textAlign: 'center' }}
                >
                  <KeyboardDatePicker
                    cancelLabel="Cancelar"
                    format="DD \d\e MMMM \d\e YYYY"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    label="Día"
                    margin="normal"
                    name="date"
                    okLabel="Aceptar"
                    onChange={(date: any, value?: string | null) => {
                      if (value) {
                        setFieldValue(
                          'decease.site.ceremonies[' + index + '].date',
                          date.valueOf()
                        )
                        updateCeremonyValues(index, 'date', date.valueOf())
                      }
                    }}
                    style={{ width: '95%' }}
                    TextFieldComponent={KeyboardPickerFieldComponent}
                    value={dateValue ? moment(dateValue) : moment().valueOf()}
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  style={{ marginTop: 16, textAlign: 'center' }}
                >
                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      label="Hora desde"
                      name="datetimeAt"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value) {
                          setFieldValue(
                            'decease.site.ceremonies[' + index + '].datetimeAt',
                            event.target.value
                          )
                          updateCeremonyValues(
                            index,
                            'datetimeAt',
                            event.target.value
                          )
                        }
                      }}
                      style={{ width: '95%' }}
                      type="time"
                      value={datetimeAtValue}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      label="Hora hasta"
                      name="datetimeTo"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value) {
                          setFieldValue(
                            'decease.site.ceremonies[' + index + '].datetimeTo',
                            event.target.value
                          )
                          updateCeremonyValues(
                            index,
                            'datetimeTo',
                            event.target.value
                          )
                        }
                      }}
                      style={{ width: '95%' }}
                      type="time"
                      value={datetimeToValue}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 26 }}>
                  <TextField
                    autoComplete="addressAliases"
                    error={!!addressAliasesError}
                    helperText={addressAliasesError}
                    label="Alias de dirección"
                    name="addressAliases"
                    onChange={(event) => {
                      handleChange(
                        'decease.site.ceremonies[' + index + '].addressAliases'
                      )
                      updateCeremonyValues(
                        index,
                        'addressAliases',
                        event.target.value
                      )
                    }}
                    style={{ width: '100%' }}
                    type="string"
                    value={addressAliasesValue}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 26 }}>
                  <GoogleMapsPlace
                    addressValue={addressValue}
                    setAddressValue={setAddressValue}
                    setLat={setLat}
                    setLng={setLng}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: 33 }}>
                  <Divider />
                  <SDeleteButtonContainer>
                    <Button
                      onClick={() => setOpenConfirmDialogToDelete(true)}
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </SDeleteButtonContainer>
                </Grid>
              </>
            </MuiPickersUtilsProvider>
          </SExpansionPanelDetails>
        </ExpansionPanel>
      </SCard>

      <ConfirmationDialog
        confirmationButtonTitle="SI, ELIMINAR"
        description="Esta acción es definitiva y no podrá deshacerse."
        handleClose={() => setOpenConfirmDialogToDelete(false)}
        handleConfirm={removeThisCeremony}
        open={openConfirmDialogToDelete}
        title="¿Desea borrar la ceremonia?"
      />
    </>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
`

const SDeleteButtonContainer = styled.div`
  text-align: center;

  .MuiButton-text {
    color: #f57c00;
    font-family: 'Roboto Medium';
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 26px;
    margin-top: 16px;
  }
`

const SExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
`

const SExpansionPanelSummary = styled(ExpansionPanelSummary)`
  .MuiExpansionPanelSummary-content {
    justify-content: center;
  }

  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.87);
  }
`

export default React.memo(CeremonyRender)
