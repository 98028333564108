import React from 'react'
import styled from 'styled-components'

interface ParagraphProps {
  children: React.ReactNode
}

const SParagraph = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto Regular';
  font-size: 14px;
  letter-spacing: 0.07px;
  line-height: 21px;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Roboto Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 24px;
    text-decoration: none;
  }
`

const Paragraph = ({ children }: ParagraphProps) => (
  <SParagraph>{children}</SParagraph>
)

export default Paragraph
