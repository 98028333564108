import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import NoSsr from '@material-ui/core/NoSsr'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles'

import Layout from '~Components/Layout'
import Loader from '~Components/Loader'
import ScrollToTop from '~Components/ScrollToTop'
import Snackbar from '~Components/Snackbar'
import Typography from '~Fonts/Typography'
import ImportScript from '~Hooks/importScript'
import CookiePolicyProvider from '~Providers/CookiePolicy.provider'
import LoaderProvider from '~Providers/Loader.provider'
import UserConfigProvider from '~Providers/UserConfig.provider'
import Router from '~Routes'
import theme from '~Theme'
import SnackbarProvider from '~Providers/Snackbar.provider'

function App() {
  ImportScript(
    'https://maps.googleapis.com/maps/api/js?key=' +
      process.env.REACT_APP_GOOGLE_API_KEY! +
      '&libraries=places&language=es&region=ES',
    document.querySelector('head'),
    'google-maps'
  )

  return (
    <NoSsr>
      <Typography />
      <StylesProvider injectFirst>
        {/* To override the current theme */}
        <MuiThemeProvider theme={theme}>
          {/* Allow share the theme with styled components */}
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <UserConfigProvider>
                <LoaderProvider>
                  <SnackbarProvider>
                    <CookiePolicyProvider>
                      <Layout>
                        <ScrollToTop />
                        <Router />
                      </Layout>
                      <Loader />
                      <Snackbar />
                    </CookiePolicyProvider>
                  </SnackbarProvider>
                </LoaderProvider>
              </UserConfigProvider>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </NoSsr>
  )
}

export default App
