import React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { Grid, MenuItem, FormControl, Select } from '@material-ui/core'

import CardTitle from '~Screens/Edition/components/CardTitle'
import { getTranslationOfCeremonyType } from '~Utils/index'

interface AddCeremonyRenderProps {
  getMenuItems: any
  handleChange: any
  selectorValue: string
}

const AddCeremonyRender = ({
  getMenuItems,
  handleChange,
  selectorValue,
}: AddCeremonyRenderProps) => {
  const menuItems = getMenuItems()

  return (
    <SCard container direction="column">
      <TitleContainer item xs={12} style={{ marginBottom: 20 }}>
        <CardTitle>Añadir ceremonia</CardTitle>
      </TitleContainer>
      <Grid item xs={12}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          {/*<InputLabel id="add-ceremony-label">Seleccionar ceremonia</InputLabel>*/}
          <Select
            displayEmpty={true}
            id="add-ceremony"
            label="Age"
            // labelId="add-ceremony-label"
            onChange={handleChange}
            value={selectorValue}
          >
            <MenuItem value="">
              <em>Seleccionar ceremonia</em>
            </MenuItem>
            {menuItems && menuItems.length
              ? menuItems.map((data: any) => {
                  return (
                    <MenuItem key={uuidv4()} value={data}>
                      {getTranslationOfCeremonyType(data)}
                    </MenuItem>
                  )
                })
              : ''}
          </Select>
        </FormControl>
      </Grid>
    </SCard>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 16px 23px;
`

const TitleContainer = styled(Grid)`
  text-align: center;
`

export default React.memo(AddCeremonyRender)
