import moment from 'moment'
import 'moment/locale/es'
import React from 'react'
import styled from 'styled-components'
import { Divider, Grid } from '@material-ui/core'
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined'
import Schedule from '@material-ui/icons/Schedule'
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined'

import SimpleMap from '~Components/SimpleMap'
import CeremonyType from '~Types/Ceremony.type'
import { getTranslationOfCeremonyType } from '~Utils/index'

interface CeremonyProps {
  ceremony: any
  color: string
}

interface SOpenGoogleMapsContainerProps {
  color: string
}

const Ceremony = ({ ceremony, color }: CeremonyProps) => {
  if (!ceremony) {
    return <></>
  }

  const goToGoogleMaps = (lat: number, lng: number) => {
    // If it's an iPhone..
    if (
      navigator.platform.indexOf('iPhone') !== -1 ||
      navigator.platform.indexOf('iPod') !== -1 ||
      navigator.platform.indexOf('iPad') !== -1
    )
      window.open(
        'maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' +
          lat +
          ',' +
          lng
      )
    else
      window.open(
        'https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' +
          lat +
          ',' +
          lng
      )
  }

  return (
    <SCard container direction="column">
      <STitle item xs={12} style={{ paddingTop: 16 }}>
        {ceremony.type === CeremonyType.OTHER && ceremony.title
          ? ceremony.title
          : getTranslationOfCeremonyType(ceremony.type)}
      </STitle>

      {ceremony.extraInformation ? <Divider style={{ marginTop: 24 }} /> : ''}
      {ceremony.extraInformation ? (
        <SExtraInformationContainer item xs={12} style={{ marginTop: 24 }}>
          {ceremony.extraInformation}
        </SExtraInformationContainer>
      ) : (
        ''
      )}

      <Divider style={{ marginTop: 24 }} />

      <SItemContainer item xs={12} style={{ marginTop: 22 }}>
        <Grid container direction="row">
          <Grid item xs={1}>
            <CalendarTodayOutlined style={{ marginRight: 9 }} />
          </Grid>
          <Grid item xs={11}>
            {moment(ceremony.date).format('DD \\d\\e MMMM, YYYY')}
          </Grid>
        </Grid>
      </SItemContainer>

      <SItemContainer item xs={12} style={{ marginTop: 16 }}>
        <Grid container direction="row">
          <Grid item xs={1}>
            <Schedule style={{ marginRight: 9 }} />
          </Grid>
          <Grid item xs={11}>
            De {ceremony.datetimeAt} a {ceremony.datetimeTo}
          </Grid>
        </Grid>
      </SItemContainer>

      {(ceremony.address || ceremony.addressAliases) && (
        <SItemContainer
          item
          xs={12}
          style={{ marginBottom: 12, marginTop: 16 }}
        >
          <Grid container direction="row">
            <Grid item xs={1}>
              <LocationOnOutlined style={{ marginRight: 9 }} />
            </Grid>
            <Grid item xs={11}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  {ceremony.addressAliases}
                </Grid>
                <Grid item xs={12}>
                  {ceremony.address}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SItemContainer>
      )}

      {ceremony.lat && ceremony.long ? (
        <SSimpleMapContainer item xs={12}>
          <SimpleMap lat={ceremony.lat} lng={ceremony.long} />
        </SSimpleMapContainer>
      ) : (
        ''
      )}

      {ceremony.lat && ceremony.long ? (
        <SOpenGoogleMapsContainer color={color} item xs={12}>
          <SOpenGoogleMaps
            onClick={() => goToGoogleMaps(ceremony.lat, ceremony.long)}
          >
            Abrir en Google Maps
          </SOpenGoogleMaps>
        </SOpenGoogleMapsContainer>
      ) : (
        ''
      )}
    </SCard>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 12px;
  width: 100%;
`

const SInformationContainer = styled(Grid)`
  color: #333333;
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.44px;
  line-height: 16px;

  svg {
    color: #333333;
    font-size: 14px;
  }
`

const SExtraInformationContainer = styled(SInformationContainer)`
  text-align: center;
`

const SItemContainer = styled(SInformationContainer)`
  padding-left: 30px;
  padding-right: 30px;
`

const SOpenGoogleMaps = styled.span`
  cursor: pointer;
`

const SOpenGoogleMapsContainer = styled(Grid)<SOpenGoogleMapsContainerProps>`
  color: ${(props: SOpenGoogleMapsContainerProps) => props.color};
  font-family: 'Roboto Medium';
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 24.5px;
  margin-top: 16px;
  padding-bottom: 16px;
  text-align: center;
`

const SSimpleMapContainer = styled(Grid)`
  margin-left: 12px;
  margin-right: 12px;
`

const STitle = styled(Grid)`
  color: #333333;
  font-family: 'Playfair Display';
  font-size: 24px;
  letter-spacing: 0.75px;
  line-height: 32px;
  text-align: center;
`

export default Ceremony
