import React from 'react'
import styled from 'styled-components'

const EditorHeader = () => {
  return (
    <>
      <SEditorContainer>
        <span>Editor</span>
      </SEditorContainer>
    </>
  )
}

const SEditorContainer = styled.div`
  align-items: center;
  background-color: rgba(147, 147, 147, 0.5);
  display: flex;
  height: 146px;
  justify-content: center;

  span {
    color: #f5f5f5;
    font-family: 'Roboto Light';
    font-size: 42px;
    font-weight: 300;
    letter-spacing: -0.29px;
  }
`

export default React.memo(EditorHeader)
