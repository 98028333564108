import React, { useState } from 'react'

import CookiePolicy from '~Contexts/CookiePolicy.context'

interface CookiePolicyProviderProps {
  children: React.ReactNode
}
const CookiePolicyProvider = ({ children }: CookiePolicyProviderProps) => {
  const accept = () => {
    setState((prevState) => {
      return {
        ...prevState,
        accepted: true,
      }
    })
  }

  const initialState = {
    accept,
    accepted: false,
  }

  const [state, setState] = useState(initialState)

  return <CookiePolicy.Provider value={state}>{children}</CookiePolicy.Provider>
}

export default CookiePolicyProvider
