import { createContext } from 'react'
import { Color } from '@material-ui/lab/Alert'

export interface SnackbarContextProps {
  closeSnackbar: () => void
  message: string
  open: boolean
  openSnackbar: (message: string, severity: Color) => void
  severity: Color
}

const initialState: SnackbarContextProps = {
  closeSnackbar: () => {},
  message: '',
  open: false,
  openSnackbar: () => {},
  severity: 'success',
}

const SnackbarContext = createContext<SnackbarContextProps>(initialState)

export default SnackbarContext
