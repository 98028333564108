enum ApiErrorCodeType {
  NOT_FOUND = 1,
  SESSION_EXPIRED = 2,
  UNAUTHORIZED = 3,
  UNAUTHORIZED_MANAGER = 4,
  NOT_PUBLISHED = 5,
  NOT_ACTIVATED = 6,
}

export default ApiErrorCodeType
