import { createContext } from 'react'

import UserConfigType from '~Types/UserConfig.type'

const defaultValue: UserConfigType = {
  isManager: false,
}

const UserConfigContext = createContext({
  ...defaultValue,
  setUserConfig: (userConfig: UserConfigType) => {},
})

export default UserConfigContext
