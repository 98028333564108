import React, { useContext, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import LoaderContext from '~Contexts/Loader.context'
import SnackbarContext from '~Contexts/Snackbar.context'
import UserConfigContext from '~Contexts/UserConfig.context'
import { getSite } from '~Services/index'
import Headboard from '~Screens/View/components/Headboard'
import TabsSection from '~Screens/View/components/TabsSection'
import {
  getSiteUrl,
  handlePageNotFoundRedirect,
  handleRedirect,
} from '~Utils/index'

interface MatchParams {
  code: string
  name: string
}
const View = (props: RouteComponentProps<MatchParams>) => {
  const nameUrl = props.match.params.name
  const codeUrl = props.match.params.code
  const siteUrl = getSiteUrl(nameUrl, codeUrl)

  const history = useHistory()
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { setUserConfig } = useContext(UserConfigContext)

  const [decease, setDecease] = React.useState<any>({})

  useEffect(
    () => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      getSite(siteUrl)
        .then((response) => {
          const redirection = handleRedirect(
            history,
            response,
            nameUrl,
            codeUrl
          )
          if (redirection) return

          setUserConfig(response.data.data.userConfig)
          setDecease(response.data.data.decease)
        })
        .catch((error) => {
          openSnackbar(error.message, 'error')
        })
        .finally(() => {
          hideLoader()
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteUrl]
  )

  return (
    <>
      <Headboard decease={decease} />
      <TabsSection decease={decease} />
    </>
  )
}

export default View
