import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined'

interface GoBackButtonProps {
  goBack: () => void
}
const GoBackButton = ({ goBack }: GoBackButtonProps) => {
  return (
    <SButton
      color="primary"
      onClick={() => goBack()}
      type="submit"
      startIcon={<KeyboardArrowLeftOutlinedIcon />}
    >
      Volver
    </SButton>
  )
}

const SButton = styled(Button)`
  .MuiButton-label {
    color: #2196f3;
    font-family: 'Roboto Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 24px;
  }

  .MuiSvgIcon-root {
    height: 30px;
    margin-right: 10px;
    width: 30px;
  }
`

export default GoBackButton
