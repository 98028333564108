import React from 'react'

import WhatsAppMessageToShareRender from '~Screens/Edition/components/WhatsAppMessageToShareRender'

interface WhatsAppMessageToShareProps {
  formik: any
}

const WhatsAppMessageToShare = ({ formik }: WhatsAppMessageToShareProps) => {
  if (
    !formik ||
    !formik.values ||
    !formik.values.decease ||
    !formik.values.decease.site
  ) {
    return <></>
  }

  const whatsAppMessageToShareError =
    formik.errors.decease &&
    formik.errors.decease.site &&
    formik.errors.decease.site.whatsAppMessageToShare
      ? formik.errors.decease.site.whatsAppMessageToShare
      : ''
  const whatsAppMessageToShareValue = formik.values.decease.site
    .whatsAppMessageToShare
    ? formik.values.decease.site.whatsAppMessageToShare
    : ''

  return (
    <WhatsAppMessageToShareRender
      handleChange={formik.handleChange}
      whatsAppMessageToShareError={whatsAppMessageToShareError}
      whatsAppMessageToShareValue={whatsAppMessageToShareValue}
    />
  )
}

export default WhatsAppMessageToShare
