import React, { useState } from 'react'

import UserConfigContext from '~Contexts/UserConfig.context'
import UserConfigType from '~Types/UserConfig.type'

interface UserConfigProviderProps {
  children: React.ReactNode
}
const UserConfigProvider = ({ children }: UserConfigProviderProps) => {
  const setUserConfig = (userConfig: UserConfigType) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...userConfig,
      }
    })
  }

  const defaultValue: UserConfigType = {
    isManager: false,
  }

  const userConfigState = {
    ...defaultValue,
    setUserConfig,
  }

  const [state, setState] = useState(userConfigState)

  return (
    <UserConfigContext.Provider value={state}>
      {children}
    </UserConfigContext.Provider>
  )
}

export default UserConfigProvider
