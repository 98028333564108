import { useFormik } from 'formik'
import React, { useContext, useEffect } from 'react'
import {
  Link,
  Redirect,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@material-ui/core'

import CookiesConfirmationDialog from '~Components/CookiesConfirmationDialog'
import RobinsonThird from '~Components/RobinsonThird'
import LoaderContext from '~Contexts/Loader.context'
import SnackbarContext from '~Contexts/Snackbar.context'
import routes from '~Routes/routes'
import { getSiteVerify, postSiteVisitor } from '~Services/index'
import Logo from '~Screens/Access/components/Logo'
import Paragraph from '~Screens/Access/components/Paragraph'
import Title from '~Screens/Access/components/Title'
import {
  getSiteUrl,
  handleFormikCustomErrorsForm,
  handlePageNotFoundRedirect,
  handlePageNotPublishedRedirect,
  handleRedirect,
} from '~Utils/index'

interface MatchParams {
  code: string
  name: string
}
const Visitor = (props: RouteComponentProps<MatchParams>) => {
  const nameUrl = props.match.params.name
  const codeUrl = props.match.params.code
  const siteUrl = getSiteUrl(nameUrl, codeUrl)

  const history = useHistory()
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { openSnackbar } = useContext(SnackbarContext)

  const [deceasedFirstname, setDeceasedFirstname] = React.useState('')
  const [isViewRedirect, setIsViewRedirect] = React.useState(false)

  useEffect(
    () => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      getSiteVerify(siteUrl)
        .then((response) => {
          const redirect = handleRedirect(history, response, nameUrl, codeUrl)
          if (redirect) return

          if (!response.data.data.site.published) {
            return handlePageNotPublishedRedirect(history)
          }

          setDeceasedFirstname(response.data.data.deceased.firstname)
        })
        .catch((error) => {
          openSnackbar(error.message, 'error')
        })
        .finally(() => {
          hideLoader()
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteUrl]
  )

  const formik = useFormik({
    initialValues: {
      email: '',
      fullname: '',
      robinsonThird: false,
      phone: '',
      privacyPolicy: false,
    },
    onSubmit: (values, actions) => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      postSiteVisitor(
        values.email,
        values.fullname,
        values.robinsonThird,
        values.phone,
        values.privacyPolicy,
        siteUrl
      )
        .then((response) => {
          if (response.data.errors.length) {
            handleFormikCustomErrorsForm(actions, response.data.errors)
          } else {
            setIsViewRedirect(true)
          }
        })
        .catch((error) => {
          openSnackbar(error.message, 'error')
        })
        .finally(() => {
          hideLoader()
        })
    },
  })

  if (isViewRedirect) {
    let route = routes.view.path
    route = route.replace(':name', nameUrl)
    route = route.replace(':code', codeUrl)
    return <Redirect to={route} />
  }

  return (
    <>
      <Grid container direction="column" style={{ padding: 35 }}>
        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 15 }}>
            <Logo />
          </Grid>
          <Grid item md={3} />
        </Grid>

        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 50 }}>
            <Title>Acceso</Title>
          </Grid>
          <Grid item md={3} />
        </Grid>

        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 15 }}>
            <Paragraph>
              La familia de {deceasedFirstname} quiere contar con usted en estos
              momentos de duelo y ha habilitado una web en la que actualizará
              información de las ceremonias y en la que puede compartir
              condolencias y recuerdos.
            </Paragraph>
            <br />
            <Paragraph>
              Por favor, identifíquese para acceder ya que vamos a compartir con
              usted datos personales.
            </Paragraph>
          </Grid>
          <Grid item md={3} />
        </Grid>

        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          style={{ width: '100%' }}
          noValidate
        >
          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 30 }}>
              <TextField
                autoComplete="fullname"
                error={!!formik.errors.fullname}
                helperText={
                  formik.errors.fullname ? formik.errors.fullname : ''
                }
                id="fullname"
                label="Nombre y apellidos"
                name="fullname"
                onChange={formik.handleChange}
                required
                style={{ width: '100%' }}
                type="string"
                value={formik.values.fullname}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
              <TextField
                autoComplete="email"
                error={!!formik.errors.email}
                helperText={formik.errors.email ? formik.errors.email : ''}
                id="email"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                style={{ width: '100%' }}
                type="string"
                value={formik.values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 20 }}>
              <TextField
                autoComplete="phone"
                error={!!formik.errors.phone}
                helperText={formik.errors.phone ? formik.errors.phone : ''}
                id="phone"
                label="Móvil"
                name="phone"
                onChange={formik.handleChange}
                style={{ width: '100%' }}
                type="tel"
                value={formik.values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 26 }}>
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    checked={formik.values.privacyPolicy}
                    color="primary"
                    onChange={formik.handleChange}
                    name="privacyPolicy"
                    value={formik.values.privacyPolicy}
                  />
                }
                label={
                  <Paragraph>
                    He leído y aceptado la
                    <span> </span>
                    <Link to={routes.privacyPolicy.path}>
                      Política de privacidad
                    </Link>
                  </Paragraph>
                }
                labelPlacement="end"
              />
              {!!formik.errors.privacyPolicy ? (
                <SFormHelperText>{formik.errors.privacyPolicy}</SFormHelperText>
              ) : (
                ''
              )}
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 24 }}>
              <RobinsonThird
                isChecked={formik.values.robinsonThird}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 56 }}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                style={{ width: '100%' }}
              >
                ACCEDER
              </Button>
            </Grid>
            <Grid item md={3} />
          </Grid>
        </form>
      </Grid>

      <CookiesConfirmationDialog />
    </>
  )
}

const SFormHelperText = styled(FormHelperText)`
  color: #f44336;
`

export default Visitor
