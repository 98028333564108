import styled from 'styled-components'
import React from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined'

interface AddCondolenceMenuProps {
  color: string
  handleOpenAddMessageDialog: any
  handleOpenAddImage: any
}

interface SSpeedDialProps {
  color: string
}

const AddMenu = ({
  color,
  handleOpenAddMessageDialog,
  handleOpenAddImage,
}: AddCondolenceMenuProps) => {
  const [open, setOpen] = React.useState(false)

  const actions = [
    {
      event: handleOpenAddMessageDialog,
      icon: <FormatQuoteIcon />,
      name: 'Condolencia',
    },
    {
      event: handleOpenAddImage,
      icon: <ImageOutlinedIcon />,
      name: 'Imagen',
    },
  ]

  const handleOpen = (event: object, reason: string) => {
    if (reason !== 'mouseEnter') {
      setOpen(true)
    }
  }

  const handleClose = (event: object, reason: string) => {
    if (reason !== 'toggle') {
      setOpen(false)
    }
  }

  return (
    <SSpeedDial
      ariaLabel="SpeedDial"
      color={color}
      direction="up"
      hidden={false}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
    >
      {actions.map((action) => (
        <SpeedDialAction
          icon={action.icon}
          key={action.name}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => {
            setOpen(false)
            action.event()
          }}
          title={''}
        />
      ))}
    </SSpeedDial>
  )
}

const SSpeedDial = styled(SpeedDial)<SSpeedDialProps>`
  .MuiSpeedDial-actionsClosed {
    display: none;
  }

  .MuiSpeedDial-fab {
    background-color: ${(props: SSpeedDialProps) => props.color};
    height: 80px;
    width: 80px;
  }
`

export default AddMenu
