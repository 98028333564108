import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'

import LoaderContext from '~Contexts/Loader.context'
import { putSiteDeactivate } from '~Services/index'
import RemoveSiteConfirmationDialog from '~Screens/Edition/components/RemoveSiteConfirmationDialog'
import { handlePageNotFoundRedirect, handleRedirect } from '~Utils/index'

interface RemoveSiteProps {
  codeUrl: string
  nameUrl: string
  siteUrl: string | null
}

const RemoveSite = ({ codeUrl, nameUrl, siteUrl }: RemoveSiteProps) => {
  const history = useHistory()
  const { showLoader, hideLoader } = useContext(LoaderContext)

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false)

  if (!siteUrl) {
    return <></>
  }

  const removeSite = () => {
    showLoader()

    putSiteDeactivate(siteUrl)
      .then((response) => {
        const redirection = handleRedirect(history, response, nameUrl, codeUrl)
        if (redirection) return

        handlePageNotFoundRedirect(history)
        // TODO show message thanks to use it?
      })
      .catch((error) => {})
      .finally(() => {
        hideLoader()
      })
  }

  return (
    <Grid container direction="row">
      <SButtonContainer item xs={12}>
        <SButton
          color="primary"
          onClick={() => setOpenConfirmDialog(true)}
          size="large"
          type="button"
          variant="contained"
        >
          ELIMINAR PÁGINA
        </SButton>
      </SButtonContainer>

      <RemoveSiteConfirmationDialog
        handleClose={() => setOpenConfirmDialog(false)}
        handleConfirm={removeSite}
        open={openConfirmDialog}
      />
    </Grid>
  )
}

const SButton = styled(Button)`
  background-color: #f44336;
  :hover {
    background-color: #f44336;
  }
`

const SButtonContainer = styled(Grid)`
  text-align: center;
`

export default RemoveSite
