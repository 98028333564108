import GoogleMapReact, { Point } from 'google-map-react'
import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn'

interface SimpleMapProps {
  height?: string
  lat: number
  lng: number
  width?: string
}

// eslint-disable-next-line no-empty-pattern
const AnyReactComponent = ({}: any) => (
  <div>
    <LocationOnIcon />
  </div>
)

const SimpleMap = ({ height, lat, lng, width }: SimpleMapProps) => {
  height = height || '140px'
  width = width || '100%'

  const center = {
      lat: lat,
      lng: lng,
    },
    zoom = 15

  const distanceToMouse = (pt: Point, mousePos: Point, markerProps?: any) => {
    if (pt && mousePos) {
      return Math.sqrt(
        (pt.x - mousePos.x) * (pt.x - mousePos.x) +
          (pt.y - mousePos.y) * (pt.y - mousePos.y)
      )
    }
    return 0
  }

  return (
    <div style={{ height: height, width: width }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY! }}
        defaultCenter={center}
        defaultZoom={zoom}
        distanceToMouse={distanceToMouse}
      >
        <AnyReactComponent lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  )
}

export default SimpleMap
