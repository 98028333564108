import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

interface CardTitleProps {
  className?: string,
  children: React.ReactNode
}

const CardTitle = ({ className, children }: CardTitleProps) => {
  return <STypography className={className}>{children}</STypography>
}

const STypography = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 24px;
`

export default CardTitle
