import { pathOr } from 'ramda'
import React from 'react'
import HeadboardRender from '~Screens/Edition/components/HeadboardRender'


interface HeadboardProps {
  formik: any
}

const Headboard = ({ formik }: HeadboardProps) => {
  const siteUrl = pathOr(null, ['values', 'decease', 'site', 'url'], formik)

  if (!siteUrl) {
    return <></>
  }

  const siteColor = pathOr(null, ['values', 'decease', 'site', 'color'], formik)
  const avatarUrl = pathOr(
    null,
    ['values', 'decease', 'site', 'avatarUrl'],
    formik
  )

  return (
    <HeadboardRender
      avatarUrl={avatarUrl}
      setFieldValue={formik.setFieldValue}
      siteColor={siteColor}
      siteUrl={siteUrl}
    />
  )
}

export default Headboard
