import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import GoBackButton from '~Components/GoBackButton'

const PrivacyPolicy = (props: RouteComponentProps) => {
  return (
    <Grid container direction="row">
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <Grid container direction="column">
          <SGoBackButtonContainer item xs={12}>
            <GoBackButton goBack={props.history.goBack} />
          </SGoBackButtonContainer>
          <STitleContainer item xs={12}>
            Política de privacidad
          </STitleContainer>
          <STextContainer item xs={12}>
            <div className="section">
              INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS:
            </div>
            <div className="subsection">
              Responsable del tratamiento de datos personales:
            </div>
            <div className="text">
              Preventiva, Compañía de Seguros y Reaseguros, S.A.
            </div>

            <div className="subsection">
              Finalidad del tratamiento de datos personales:
            </div>
            <div className="text">
              Gestión de las solicitudes de información que se realizan a través
              del formulario web, con el fin de que el interesado pueda recibir
              información del sepelio del asegurado por medios electrónicos.
            </div>
            <div className="text">
              Realizar acciones comerciales de los productos aseguradores del
              Grupo Preventiva Seguros por medios electrónicos, en caso de que
              el interesado hubiera manifestado su consentimiento expreso. La
              retirada del consentimiento para esta finalidad no condicionará en
              ningún caso el servicio de información referido en el párrafo
              anterior.
            </div>

            <div className="subsection">Legitimación:</div>
            <div className="text">
              La base legal para el tratamiento de los datos se fundamenta en el
              cumplimiento de la obligación de atender las solicitudes de los
              usuarios, así como el consentimiento del interesado para las
              finalidades con interés comercial.
            </div>

            <div className="subsection">Destinatarios:</div>
            <div className="text">
              Los datos personales facilitados por el interesado podrán ser
              comunicados a entidades del Grupo Preventiva o a sus
              colaboradores, a organismos públicos y autoridades competentes,
              así como a las fuerzas y cuerpos de seguridad del Estado, juzgados
              y tribunales.
            </div>
            <div className="text">
              No se comunicarán los datos facilitados a otros terceros, ni se
              realizarán transferencias internacionales, salvo consentimiento
              expreso de su titular.
            </div>

            <div className="subsection">Derechos de los interesados:</div>
            <div className="text">
              Los interesados tienen derecho a acceder, rectificar y suprimir
              sus datos, a limitar u oponerse a su tratamiento y a solicitar su
              portabilidad, tal y como se explica en la información adicional.
            </div>

            <div className="section">
              INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS:
            </div>
            <div className="text">
              La presente política de privacidad es aplicable al tratamiento de
              los datos que facilite el interesado a través del formulario web
              adjunto, con el fin de pueda conocer la información que Grupo
              Preventiva Seguros pone a su disposición con relación al sepelio
              del asegurado, así como para recibir información sobre productos
              aseguradores si ha manifestado su consentimiento.
            </div>
            <div className="text">
              El envío del formulario cumplimentado por parte de los interesados
              implica la aceptación de la presente política. Por otro lado, el
              marcado de la casilla establecida al efecto implica el
              consentimiento expreso para el tratamiento de datos con fines
              comerciales y a la recepción de comunicaciones por medios
              electrónicos.
            </div>

            <div className="subsection">Responsable del Tratamiento:</div>
            <div className="text">
              El Responsable del tratamiento de datos personales que el
              interesado facilite es PREVENTIVA, COMPAÑÍA DE SEGUROS Y
              REASEGUROS, S.A., (en adelante la Entidad), con NIF A28027332 y
              domicilio social en la calle Arminza, 2, 28023 de Madrid.
            </div>
            <div className="text">
              Con el fin de garantizar el adecuado cumplimiento de la normativa
              aplicable, la Entidad ha designado un Delegado de Protección de
              Datos (DPD) con el que se puede contactar en la siguiente
              dirección: dpd@grupopreventiva.com.
            </div>

            <div className="subsection">Tipología de los datos tratados:</div>
            <div className="text">
              El Responsable tratará todos aquellos obtenidos directamente del
              interesado por la cumplimentación del formulario adjunto, que
              consistirán en datos identificativos y de contacto.
            </div>

            <div className="subsection">Finalidad del Tratamiento:</div>
            <div className="text">
              Gestión de las solicitudes de información que se realizan a través
              del formulario web, con el fin de que el interesado pueda recibir
              información del sepelio del asegurado por medios electrónicos.
            </div>
            <div className="text">
              Realizar acciones comerciales por cualquier medio (correo postal,
              electrónico en caso de que no se hubiera manifestado oposición,
              sms o teléfono) de los propios productos de seguro de la Entidad
              que pudieran ser de interés para el titular de los datos, en base
              al interés legítimo o consentimiento del interesado.
            </div>

            <div className="subsection">Legitimación del Tratamiento:</div>
            <div className="text">
              La base legal para el tratamiento de los datos se fundamenta en el
              cumplimiento de la obligación de atender las solicitudes de los
              usuarios relacionadas con el servicio de información del sepelio
              del asegurado.
            </div>
            <div className="text">
              El tratamiento de los datos con finalidad comercial se fundamenta
              en el consentimiento del interesado, que podrá retirarse en
              cualquier momento.
            </div>

            <div className="subsection">Destinatarios:</div>
            <div className="text">
              Los datos personales facilitados en el formulario serán
              comunicados Los datos personales que sean facilitados por el
              interesado podrán ser comunicados a las entidades que forman parte
              del Grupo Preventiva Seguros, con fines administrativos internos y
              de centralización de recursos. También podrán comunicarse a
              organismos de la Administración Pública y autoridades competentes,
              previo requerimiento o en defensa de los intereses del Grupo
              Preventiva.
            </div>

            <div className="subsection">Plazo de conservación:</div>
            <div className="text">
              La Entidad conservará los datos personales mientras dure la
              solicitud de información con respecto al servicio solicitado, y
              con posterioridad a la misma salvo que el interesado se oponga o
              limite un tratamiento concreto, o retire su consentimiento. En
              cualquier caso una vez finalizado la entidad mantendrá los datos
              debidamente bloqueados y estando únicamente disponibles a
              requerimiento de las autoridades competentes, jueces y tribunales
              o Administración Pública, durante los plazos de prescripción legal
              que resulten de aplicación.
            </div>

            <div className="subsection">Derechos de los interesados:</div>
            <div className="text">
              Los interesados tienen derecho a conocer si la Entidad está
              tratando datos personales que les conciernan así como a solicitar
              el ejercicio de los siguientes derechos:
            </div>
            <div className="text">
              <ul>
                <li>Acceso a sus datos personales o a su rectificación.</li>
                <li>Supresión de los datos.</li>
                <li>Limitación de su tratamiento u oposición al mismo.</li>
                <li>
                  Portabilidad de los datos, cuando técnicamente fuera posible.
                </li>
              </ul>
            </div>
            <div className="text">
              Los interesados pueden ejercer los derechos mencionados dirigiendo
              una comunicación a la atención del Delegado de Protección de Datos
              de la Entidad, bien por correo electrónico a la dirección
              dpd@grupopreventiva.com, o bien por correo postal a la atención
              DPD PREVENTIVA SEGUROS, a la calle Arminza, 2, 28023 de Madrid.
            </div>
            <div className="text">
              En caso de no obtener satisfacción en el ejercicio de sus
              derechos, el interesado tiene la potestad de presentar una
              reclamación ante la Agencia Española de Protección de Datos.
            </div>
          </STextContainer>
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  )
}

const SGoBackButtonContainer = styled(Grid)`
  margin-top: 80px;
`

const STextContainer = styled(Grid)`
  margin-bottom: 182px;
  margin-top: 32px;

  .section {
    font-family: 'Roboto Medium';
    font-size: 19px;
    font-weight: 300;
    margin-top: 40px;
  }

  .subsection {
    font-family: 'Roboto Medium';
    font-size: 15px;
    font-weight: 300;
    margin-top: 40px;
  }

  .text {
    font-family: 'Roboto Light';
    font-size: 15px;
    font-weight: 300;
    margin-top: 20px;
  }
`

const STitleContainer = styled(Grid)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Light';
  font-size: 42px;
  font-weight: 300;
  margin-top: 32px;
  letter-spacing: 0.09px;
  line-height: 56px;
  text-align: center;
`

export default PrivacyPolicy
