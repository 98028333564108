import { useFormik } from 'formik'
import React, { useContext, useEffect } from 'react'
import {
  Link,
  Redirect,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  Checkbox,
  Grid,
  FormControlLabel,
  Link as MaterialLink,
  TextField,
} from '@material-ui/core'

import CookiesConfirmationDialog from '~Components/CookiesConfirmationDialog'
import ConfirmationDialog from '~Components/ConfirmationDialog'
import LoaderContext from '~Contexts/Loader.context'
import routes from '~Routes/routes'
import { getSiteVerify, postSiteLogin } from '~Services/index'
import Logo from '~Screens/Access/components/Logo'
import Paragraph from '~Screens/Access/components/Paragraph'
import Title from '~Screens/Access/components/Title'
import {
  getSiteUrl,
  handleFormikCustomErrorsForm,
  handlePageNotFoundRedirect,
  handleRedirect,
} from '~Utils/index'

interface MatchParams {
  code: string
  name: string
}
const Manager = (props: RouteComponentProps<MatchParams>) => {
  const nameUrl = props.match.params.name
  const codeUrl = props.match.params.code
  const siteUrl = getSiteUrl(nameUrl, codeUrl)

  let accessNewManagerUrl = routes.accessNewManager.path
  accessNewManagerUrl = accessNewManagerUrl.replace(':name', nameUrl)
  accessNewManagerUrl = accessNewManagerUrl.replace(':code', codeUrl)

  const history = useHistory()
  const { showLoader, hideLoader } = useContext(LoaderContext)

  const [isEditionRedirect, setIsEditionRedirect] = React.useState(false)
  const [managerName, setManagerName] = React.useState('')
  const [
    openWhereIsMyPasswordDialog,
    setOpenWhereIsMyPasswordDialog,
  ] = React.useState(false)

  const handleClickOpenWhereIsMyPasswordDialog = () => {
    setOpenWhereIsMyPasswordDialog(true)
  }
  const onCloseWhereIsMyPasswordDialog = () => {
    setOpenWhereIsMyPasswordDialog(false)
  }

  useEffect(
    () => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      getSiteVerify(siteUrl)
        .then((response) => {
          const redirect = handleRedirect(history, response, nameUrl, codeUrl)
          if (redirect) return

          setManagerName(response.data.data.manager.firstname)
        })
        .catch((error) => {
          // return setIsPageNotFoundRedirect(true)
        })
        .finally(() => {
          hideLoader()
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteUrl]
  )

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: (values, actions) => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      postSiteLogin(values.password, siteUrl)
        .then((response) => {
          const redirect = handleRedirect(history, response, nameUrl, codeUrl)
          if (redirect) return

          if (response.data.errors.length) {
            handleFormikCustomErrorsForm(actions, response.data.errors)
          } else {
            setIsEditionRedirect(true)
          }
        })
        .catch((error) => {
          // return setIsPageNotFoundRedirect(true)
        })
        .finally(() => {
          hideLoader()
        })
    },
  })

  if (isEditionRedirect) {
    let route = routes.edition.path
    route = route.replace(':name', nameUrl)
    route = route.replace(':code', codeUrl)
    return <Redirect to={route} />
  }

  return (
    <>
      <Grid container direction="column" style={{ padding: 37 }}>
        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 19 }}>
            <Logo />
          </Grid>
          <Grid item md={3} />
        </Grid>

        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 59 }}>
            <Title>Hola {managerName}</Title>
          </Grid>
          <Grid item md={3} />
        </Grid>

        <Grid container direction="row">
          <Grid item md={3} />
          <Grid item xs={12} md={6} style={{ marginTop: 16 }}>
            <Paragraph>
              Por favor, introduzca la contraseña que le enviamos por WhatsApp
            </Paragraph>
          </Grid>
          <Grid item md={3} />
        </Grid>

        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          style={{ width: '100%' }}
          noValidate
        >
          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 32 }}>
              <TextField
                autoComplete="password"
                error={!!formik.errors.password}
                helperText={
                  formik.errors.password ? formik.errors.password : ''
                }
                id="password"
                label="Contraseña"
                name="password"
                onChange={formik.handleChange}
                required
                style={{ width: '100%' }}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 9 }}>
              <SFormControlLabel
                control={<Checkbox color="primary" />}
                label="Recordar contraseña"
                labelPlacement="end"
                value="end"
              />
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 31 }}>
              <Paragraph>
                ¿Dónde tengo la contraseña? &nbsp;&nbsp;
                <MaterialLink
                  href="#"
                  onClick={handleClickOpenWhereIsMyPasswordDialog}
                >
                  Pulsa aquí
                </MaterialLink>
              </Paragraph>
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 31 }}>
              <Paragraph>
                ¿No es usted {managerName}? &nbsp;&nbsp;
                <Link to={accessNewManagerUrl}>Por favor, identifíquese</Link>
              </Paragraph>
            </Grid>
            <Grid item md={3} />
          </Grid>

          <Grid container direction="row">
            <Grid item md={3} />
            <Grid item xs={12} md={6} style={{ marginTop: 70 }}>
              <Button
                color="primary"
                size="large"
                style={{ width: '100%' }}
                type="submit"
                variant="contained"
              >
                ACCEDER
              </Button>
            </Grid>
            <Grid item md={3} />
          </Grid>
        </form>
      </Grid>

      <CookiesConfirmationDialog />

      <ConfirmationDialog
        confirmationButtonTitle="DE ACUERDO"
        description="La contraseña se envió en el mensaje de WhatsApp de Preventiva Seguros"
        handleClose={onCloseWhereIsMyPasswordDialog}
        handleConfirm={onCloseWhereIsMyPasswordDialog}
        open={openWhereIsMyPasswordDialog}
        title="¿Dónde tengo la contraseña?"
      />
    </>
  )
}

const SFormControlLabel = styled(FormControlLabel)`
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto Regular';
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  padding-left: 5px;
  span {
    font-size: 14px;
  }
`

export default Manager
