import React from 'react'
import styled from 'styled-components'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import CardTitle from '~Screens/Edition/components/CardTitle'

interface WhatsAppMessageToShareRenderProps {
  handleChange: any
  whatsAppMessageToShareError: string
  whatsAppMessageToShareValue: string
}

const WhatsAppMessageToShareRender = ({
  handleChange,
  whatsAppMessageToShareError,
  whatsAppMessageToShareValue,
}: WhatsAppMessageToShareRenderProps) => {
  return (
    <SCard container direction="column">
      <ExpansionPanel defaultExpanded={true}>
        <SExpansionPanelSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <CardTitle>Mensaje de WhatsApp</CardTitle>
        </SExpansionPanelSummary>
        <SExpansionPanelDetails>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <TextField
              autoComplete="whatsAppMessageToShare"
              error={!!whatsAppMessageToShareError}
              helperText={whatsAppMessageToShareError}
              id="whatsAppMessageToShare"
              label="Mensaje a compartir"
              multiline={true}
              name="whatsAppMessageToShare"
              onChange={handleChange('decease.site.whatsAppMessageToShare')}
              required
              rows={13}
              style={{ width: '100%' }}
              type="string"
              value={whatsAppMessageToShareValue}
              variant="outlined"
            />
          </Grid>
        </SExpansionPanelDetails>
      </ExpansionPanel>
    </SCard>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
`

const SExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
`

const SExpansionPanelSummary = styled(ExpansionPanelSummary)`
  .MuiExpansionPanelSummary-content {
    justify-content: center;
  }

  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.87);
  }
`

export default React.memo(WhatsAppMessageToShareRender)
