import moment from 'moment'
import React, { useCallback } from 'react'

import AddCeremonyRender from '~Screens/Edition/components/AddCeremonyRender'
import CeremonyType from '~Types/Ceremony.type'
import { getTranslationOfCeremonyType } from '~Utils/index'

interface AddCeremonyProps {
  ceremonies: any
  setFieldValue: any
}

const AddCeremony = ({ ceremonies, setFieldValue }: AddCeremonyProps) => {
  const [selectorValue, setSelectorValue] = React.useState('')

  const getMenuItems = useCallback(
    () => {
      const menuItems: Array<string> = []
      const ceremonyTypeValues = Object.values(CeremonyType)
      for (const currentCeremonyValue in ceremonyTypeValues) {
        if (
          !ceremonies.find((ceremony: any) => {
            return ceremony.type === ceremonyTypeValues[currentCeremonyValue]
          })
        ) {
          menuItems.push(ceremonyTypeValues[currentCeremonyValue])
        }
      }
      return menuItems
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ceremonies.length]
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: CeremonyType }>) => {
      if (event.target.value) {
        ceremonies.unshift({
          address: '',
          date: moment().valueOf(),
          datetimeAt: '00:00',
          datetimeTo: '00:00',
          extraInformation: '',
          type: event.target.value,
          title: getTranslationOfCeremonyType(event.target.value),
        })
        setFieldValue('decease.site.ceremonies', ceremonies)
        setSelectorValue('')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ceremonies.length]
  )

  return (
    <AddCeremonyRender
      getMenuItems={getMenuItems}
      handleChange={handleChange}
      selectorValue={selectorValue}
    />
  )
}

export default AddCeremony
