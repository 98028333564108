import React from 'react'
import styled from 'styled-components'

import LogoImage from '~Assets/img/preventiva-logo.svg'

const SLogo = styled.div`
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 57px;
    max-width: 386px;
  }
`

const Logo = () => (
  <SLogo>
    <LogoImage />
  </SLogo>
)

export default Logo
