import { useFormik } from 'formik'
import { pathOr } from 'ramda'
import React, { useContext, useEffect } from 'react'
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

import LoaderContext from '~Contexts/Loader.context'
import SnackbarContext from '~Contexts/Snackbar.context'
import UserConfigContext from '~Contexts/UserConfig.context'
import { useWidth } from '~Hooks/useWidth'
import routes from '~Routes/routes'
import { getSiteEdition, putSite, putSitePublish } from '~Services/index'
import AddCeremony from '~Screens/Edition/components/AddCeremony'
import Ceremonies from '~Screens/Edition/components/Ceremonies'
import EditorHeader from '~Screens/Edition/components/EditorHeader'
import Headboard from '~Screens/Edition/components/Headboard'
import Obituary from '~Screens/Edition/components/Obituary'
import RemoveSite from '~Screens/Edition/components/RemoveSite'
import WhatsAppMessageToShare from '~Screens/Edition/components/WhatsAppMessageToShare'
import {
  getSiteUrl,
  getVisitorSiteUrl,
  handleFormikCustomErrorsForm,
  handlePageNotFoundRedirect,
  handleRedirect,
  openWhatsAppToShareMessage,
} from '~Utils/index'

interface MatchParams {
  code: string
  name: string
}
const Edition = (props: RouteComponentProps<MatchParams>) => {
  const nameUrl = props.match.params.name
  const codeUrl = props.match.params.code
  const siteUrl = getSiteUrl(nameUrl, codeUrl)

  const history = useHistory()
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { openSnackbar } = useContext(SnackbarContext)
  const { setUserConfig } = useContext(UserConfigContext)
  const width = useWidth()

  const [decease, setDecease] = React.useState<any>({})
  const [isViewRedirect, setIsViewRedirect] = React.useState(false)
  const [needPublish, setNeedPublish] = React.useState(false)
  const [needShareInWhatsApp, setNeedShareInWhatsApp] = React.useState(false)

  const getSiteEditionAux = () => {
    showLoader()

    if (!siteUrl) {
      return handlePageNotFoundRedirect(history)
    }

    getSiteEdition(siteUrl)
      .then((response) => {
        const redirection = handleRedirect(history, response, nameUrl, codeUrl)
        if (redirection) return

        setUserConfig(response.data.data.userConfig)
        setDecease(response.data.data.decease)
      })
      .catch((error) => {
        openSnackbar(error.message, 'error')
      })
      .finally(() => {
        hideLoader()
      })
  }

  useEffect(
    () => {
      getSiteEditionAux()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteUrl]
  )

  const handleOpenWhatsAppToShareMessage = (siteUrl: string) => {
    const whatsAppMessageToShare = pathOr(
      null,
      ['values', 'decease', 'site', 'whatsAppMessageToShare'],
      formik
    )

    const message = whatsAppMessageToShare
      ? whatsAppMessageToShare + '\n\n' + getVisitorSiteUrl(siteUrl)
      : getVisitorSiteUrl(siteUrl)

    openWhatsAppToShareMessage(message)
  }

  const publishSite = () => {
    showLoader()

    if (!siteUrl) {
      return handlePageNotFoundRedirect(history)
    }

    putSitePublish(true, siteUrl)
      .then((response) => {
        const redirection = handleRedirect(history, response, nameUrl, codeUrl)
        if (redirection) return

        getSiteEditionAux()
      })
      .catch((error) => {
        openSnackbar(error.message, 'error')
      })
      .finally(() => {
        hideLoader()
      })
  }

  const formik = useFormik({
    initialValues: { decease: decease },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      showLoader()

      if (!siteUrl) {
        return handlePageNotFoundRedirect(history)
      }

      putSite(siteUrl, values.decease)
        .then((response) => {
          const redirection = handleRedirect(
            history,
            response,
            nameUrl,
            codeUrl
          )
          if (redirection) return

          if (response.data.errors.length) {
            handleFormikCustomErrorsForm(actions, response.data.errors)
            window.scrollTo(0, 0)
            openSnackbar(
              'Hay errores en el formulario, corríjalos para continuar, por favor',
              'error'
            )
            return
          }

          if (needPublish) {
            publishSite()
          }

          if (needShareInWhatsApp) {
            handleOpenWhatsAppToShareMessage(siteUrl)
          } else {
            setIsViewRedirect(true)
          }
        })
        .catch((error) => {
          openSnackbar(error.message, 'error')
        })
        .finally(() => {
          setNeedPublish(false)
          setNeedShareInWhatsApp(false)
          hideLoader()
        })
    },
  })

  if (isViewRedirect) {
    let route = routes.view.path
    route = route.replace(':name', nameUrl)
    route = route.replace(':code', codeUrl)
    return <Redirect to={route} />
  }

  const ceremonies = pathOr(
    [],
    ['values', 'decease', 'site', 'ceremonies'],
    formik
  )

  if (!siteUrl) {
    return <></>
  }

  return (
    <>
      <SGridContainer container direction="column">
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          style={{ width: '100%' }}
          noValidate
        >
          <Grid item xs={12}>
            <EditorHeader />
          </Grid>

          {decease && decease.site && decease.site.published && (
            <SRemoveSiteContainer container direction="row">
              <RemoveSite
                codeUrl={codeUrl}
                nameUrl={nameUrl}
                siteUrl={siteUrl}
              />
            </SRemoveSiteContainer>
          )}

          <Grid container direction="row">
            <Grid item md={2} />
            <SSectionContainer
              item
              xs={12}
              md={4}
              style={{ marginTop: width === 'xs' || width === 'sm' ? -15 : 16 }}
            >
              <Obituary formik={formik} />
            </SSectionContainer>

            <SSectionContainer item xs={12} md={4} style={{ marginTop: 16 }}>
              <Headboard formik={formik} />
            </SSectionContainer>
            <Grid item md={2} />

            <Grid item md={2} />
            <SSectionContainer item xs={12} md={8} style={{ marginTop: 16 }}>
              <AddCeremony
                ceremonies={ceremonies}
                setFieldValue={formik.setFieldValue}
              />
            </SSectionContainer>
            <Grid item md={2} />

            <Grid item md={2} />
            <SSectionContainer item xs={12} md={8}>
              <Ceremonies formik={formik} />
            </SSectionContainer>
            <Grid item md={2} />

            <Grid item md={2} />
            <SSectionContainer item xs={12} md={8} style={{ marginTop: 16 }}>
              <WhatsAppMessageToShare formik={formik} />
            </SSectionContainer>
            <Grid item md={2} />

            <Grid item md={2} />
            <SSectionButtonContainer
              item
              xs={12}
              md={8}
              style={{ marginTop: 32, marginBottom: 0 }}
            >
              <Button
                color="primary"
                type="submit"
                endIcon={<VisibilityIcon />}
              >
                Guardar y Previsualizar
              </Button>
            </SSectionButtonContainer>
            <Grid item md={2} />

            {decease && decease.site && (
              <>
                <Grid item md={2} />
                <SSectionButtonContainer
                  item
                  xs={12}
                  md={8}
                  style={{ marginBottom: 2 }}
                >
                  {decease.site.published ? (
                    <Button
                      color="primary"
                      onClick={() => {
                        setNeedShareInWhatsApp(true)
                        formik.submitForm()
                      }}
                      size="large"
                      type="button"
                      variant="contained"
                    >
                      GUARDAR Y COMPARTIR
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        setNeedPublish(true)
                        setNeedShareInWhatsApp(true)
                        formik.submitForm()
                      }}
                      size="large"
                      type="button"
                      variant="contained"
                    >
                      PUBLICAR Y COMPARTIR
                    </Button>
                  )}
                </SSectionButtonContainer>
                <Grid item md={2} />

                <Grid item md={2} />
                <SSectionButtonContainer
                  item
                  xs={12}
                  md={8}
                  style={{ marginTop: 0, marginBottom: 5, fontSize: 12 }}
                >
                  Si utiliza Iphone desactive bloquear ventanas en:
                </SSectionButtonContainer>
                <Grid item md={2} />
                <Grid item md={2} />
                <SSectionButtonContainer
                  item
                  xs={12}
                  md={8}
                  style={{ marginTop: 0, marginBottom: 35, fontSize: 12 }}
                >
                  Ajustes>Safari>General
                </SSectionButtonContainer>
                <Grid item md={2} />                
              </>
            )}
          </Grid>
        </form>
      </SGridContainer>
    </>
  )
}

const SSectionContainer = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
`

const SSectionButtonContainer = styled(SSectionContainer)`
  text-align: center;
`

const SGridContainer = styled(Grid)`
  background-color: #f5f5f5;
  flex: 1;
`

const SRemoveSiteContainer = styled(Grid)`
  margin-top: 45px;
  margin-bottom: 54px;
`

export default Edition
