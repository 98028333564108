import React from 'react'
import styled from 'styled-components'
import { Avatar, Grid } from '@material-ui/core'

interface HeadboardProps {
  decease: any
}
const Headboard = ({ decease }: HeadboardProps) => {
  if (!decease || Object.entries(decease).length === 0) {
    return <></>
  }

  return (
    <Grid container direction="column">
      <SColoredSection item xs={12} color={decease.site.color} />
      <SAvatarSection item xs={12} style={{ marginTop: -100 }}>
        <SAvatar alt="Foto del fallecido" src={`${decease.site.avatarUrl}`} />
      </SAvatarSection>
      <SFullName item xs={12} style={{ marginTop: 8 }}>
        {decease.deceased.firstname} {decease.deceased.lastname}
      </SFullName>
      <SDep item xs={12} style={{ marginTop: 5 }}>
        (D.E.P.)
      </SDep>
      <Grid container direction="row">
        <Grid item xs={2} md={4} />
        <SObituary item xs={8} md={4} style={{ marginTop: 24 }}>
          {decease.site.title}
        </SObituary>
        <Grid item xs={2} md={4} />
      </Grid>
    </Grid>
  )
}

const SAvatar = styled(Avatar)`
  border: 5px solid white;
  height: 161px;
  width: 161px;
`

const SAvatarSection = styled(Grid)`
  display: flex;
  justify-content: center;
`

interface SColoredSectionProps {
  color: string
}
const SColoredSection = styled(Grid)<SColoredSectionProps>`
  background-color: ${(props: SColoredSectionProps) => props.color};
  min-height: 146px;
`

const SDep = styled(Grid)`
  color: #333333;
  font-family: 'Playfair Display';
  font-size: 22px;
  letter-spacing: 1.13px;
  line-height: 32px;
  text-align: center;
`

const SFullName = styled(Grid)`
  color: #333333;
  font-family: 'Playfair Display';
  font-size: 30px;
  letter-spacing: 1.13px;
  line-height: 32px;
  text-align: center;
`

const SObituary = styled(Grid)`
  color: #818181;
  font-family: 'Roboto Light';
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 20px;
  text-align: center;
`

export default Headboard
