import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import React from 'react'
import styled from 'styled-components'
import { Grid, TextField, Typography } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@material-ui/lab/Autocomplete'

const autocompleteService = { current: null }
const placeService = { current: null }

interface GoogleMapsPlaceProps {
  addressValue: string
  setAddressValue: any
  setLat: any
  setLng: any
  width?: string
}

export default function GoogleMapsPlace({
  addressValue,
  setAddressValue,
  setLat,
  setLng,
  width,
}: GoogleMapsPlaceProps) {
  width = width || '100%'

  let addressValueFormatted: google.maps.places.AutocompletePrediction | null
  if (addressValue) {
    addressValueFormatted = {
      description: addressValue,
      id: '',
      matched_substrings: [],
      place_id: '',
      reference: '',
      structured_formatting: {
        main_text: addressValue,
        main_text_matched_substrings: [
          {
            length: 0,
            offset: 0,
          },
        ],
        secondary_text: 'addressValue',
      },
      terms: [],
      types: [],
    }
  } else {
    addressValueFormatted = null
  }

  const [
    value,
    setValue,
  ] = React.useState<google.maps.places.AutocompletePrediction | null>(
    addressValueFormatted
  )
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  const placeServiceGetDetailsCallback = (
    placeResult: google.maps.places.PlaceResult
  ) => {
    setLat(placeResult.geometry ? placeResult.geometry.location.lat() : null)
    setLng(placeResult.geometry ? placeResult.geometry.location.lng() : null)
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (
            results?: google.maps.places.AutocompletePrediction[]
          ) => void
        ) => {
          ;(autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        200
      ),
    []
  )

  React.useEffect(() => {
    let active = true

    if (
      !autocompleteService.current &&
      (window as any).google &&
      (window as any).google.maps &&
      (window as any).google.maps.places
    ) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (
      !placeService.current &&
      (window as any).google &&
      (window as any).google.maps &&
      (window as any).google.maps.places
    ) {
      const googleMapsPlaceDiv = document.createElement('div')
      googleMapsPlaceDiv.id = 'googleMapsPlaceInput'
      placeService.current = new (window as any).google.maps.places.PlacesService(
        googleMapsPlaceDiv
      )
    }
    if (!autocompleteService.current || !placeService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      { input: inputValue },
      (results?: google.maps.places.AutocompletePrediction[]) => {
        if (active) {
          let newOptions = [] as google.maps.places.AutocompletePrediction[]

          if (value) {
            newOptions = [value]
          }

          if (results) {
            newOptions = [...newOptions, ...results]
          }

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <SAutocompleteContainer>
      <Autocomplete
        style={{ width: width }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(
          event: any,
          newValue: google.maps.places.AutocompletePrediction | null
        ) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setAddressValue(newValue ? newValue.description : '')
          setValue(newValue)

          if (placeService.current && newValue && newValue.place_id) {
            const request: google.maps.places.PlaceDetailsRequest = {
              placeId: newValue.place_id,
            }
            ;(placeService.current as any).getDetails(
              request,
              placeServiceGetDetailsCallback
            )
          } else {
            setLat(null)
            setLng(null)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Dirección"
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [
              match.offset,
              match.offset + match.length,
            ])
          )

          return (
            <Grid container alignItems="center">
              <Grid item>
                <SLocationOnIcon />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
    </SAutocompleteContainer>
  )
}

const SAutocompleteContainer = styled.div`
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`

const SLocationOnIcon = styled(LocationOnIcon)`
  margin-right: 2px;
`
