import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const NoCondolences = () => {
  return (
    <Grid container direction="row">
      <Grid item xs={2} />
      <STextContainer item xs={8}>
        Publique sus condolencias o comparta una imagen.
      </STextContainer>
      <Grid item xs={2} />
    </Grid>
  )
}

const STextContainer = styled(Grid)`
  color: #acacac;
  font-size: 18px;
  height: 100%;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 275px;
  margin-top: 113px;
  text-align: center;
`

export default NoCondolences
