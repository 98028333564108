import React from 'react'
import { Checkbox, FormControlLabel, Link } from '@material-ui/core'

import Paragraph from '~Screens/Access/components/Paragraph'

interface RobinsonThirdProps {
  isChecked: boolean
  onChange: any
}
const RobinsonThird = ({ isChecked, onChange }: RobinsonThirdProps) => {
  return (
    <FormControlLabel
      value="end"
      control={
        <Checkbox
          checked={isChecked}
          color="primary"
          onChange={onChange}
          name="robinsonThird"
          value={isChecked}
        />
      }
      label={
        <Paragraph>
          Deseo mantenerme informado de este servicio y de promociones
          exclusivas ofrecidas por el Grupo Preventiva Seguros, mediante
          comunicaciones electrónicas.
          <span> </span>
          <Link
            href="#"
            onClick={() => {
              console.info('Not yet')
            }}
          >
            Más información
          </Link>
        </Paragraph>
      }
      labelPlacement="end"
    />
  )
}

export default RobinsonThird
