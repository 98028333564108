import React from 'react'
import styled from 'styled-components'
import { Button, Dialog, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface RemoveSiteConfirmationDialogProps {
  handleClose: () => void
  handleConfirm: () => void
  open: boolean
}

const RemoveSiteConfirmationDialog = ({
  handleClose,
  handleConfirm,
  open,
}: RemoveSiteConfirmationDialogProps) => (
  <Dialog fullWidth={true} onClose={handleClose} open={open}>
    <Grid container direction="column">
      <SCloseIconContainer item xs={12}>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </SCloseIconContainer>

      <Grid container direction="row" style={{ marginTop: 35 }}>
        <Grid item xs={1} />
        <STitleContainer item xs={10}>
          ¿Desea eliminar la web con toda la información y fotos que contiene?
        </STitleContainer>
        <Grid item xs={1} />
      </Grid>

      <Grid container direction="row" style={{ marginTop: 20 }}>
        <Grid item xs={3} />
        <SInfoContainer item xs={6}>
          Esta acción es definitiva y no podrá deshacerse.
        </SInfoContainer>
        <Grid item xs={3} />
      </Grid>

      <SButtonContainer
        item
        xs={12}
        style={{ marginBottom: 23, marginTop: 35 }}
      >
        <Button onClick={handleConfirm} color="primary">
          Sí, Eliminar
        </Button>
      </SButtonContainer>
    </Grid>
  </Dialog>
)

const SButtonContainer = styled(Grid)`
  text-align: center;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 24px;

  button {
    color: #f44336;
  }
`

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SInfoContainer = styled(Grid)`
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto Regular';
  font-size: 16px;
  letter-spacing: 0.09px;
  line-height: 24px;
  text-align: center;
`

const STitleContainer = styled(Grid)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  text-align: center;
`

export default RemoveSiteConfirmationDialog
