import React, { useState } from 'react'
import { Color } from '@material-ui/lab/Alert'

import Snackbar, { SnackbarContextProps } from '~Contexts/Snackbar.context'

interface SnackbarProviderProps {
  children: React.ReactNode
}
const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const closeSnackbar = () => {
    setState((prevState) => {
      return {
        ...prevState,
        open: false,
      }
    })
  }

  const openSnackbar = (message: string, severity: Color) => {
    setState((prevState) => {
      return {
        ...prevState,
        message: message,
        open: true,
        severity: severity,
      }
    })
  }

  const initialState: SnackbarContextProps = {
    closeSnackbar,
    message: '',
    open: false,
    openSnackbar,
    severity: 'success',
  }

  const [state, setState] = useState(initialState)

  return <Snackbar.Provider value={state}>{children}</Snackbar.Provider>
}

export default SnackbarProvider
