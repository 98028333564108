import { useFormik } from 'formik'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Dialog, Grid, IconButton, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ImageIcon from '@material-ui/icons/Image'
import PublishIcon from '@material-ui/icons/Publish'

import LoaderContext from '~Contexts/Loader.context'
import { postSiteCondolenceImage } from '~Services/index'
import { handleFormikCustomErrorsForm } from '~Utils/index'

const MAX_TITLE_LENGTH = 50

interface AddCondolenceMessageImageProps {
  decease: any
  handleSuccessfulSubmit: any
  handleClose: any
  open: any
}
const AddImageDialog = ({
  decease,
  handleSuccessfulSubmit,
  handleClose,
  open,
}: AddCondolenceMessageImageProps) => {
  const { hideLoader, showLoader } = useContext(LoaderContext)

  const [
    fileToUpload,
    setFileToUpload,
  ] = React.useState<HTMLInputElement | null>(null)
  const [fileToUploadPreview, setFileToUploadPreview] = React.useState('')

  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      setFileToUploadPreview(URL.createObjectURL(event.target.files[0]))
    }
  }

  const onClose = () => {
    resetForm()
    handleClose()
  }

  const resetForm = () => {
    formik.resetForm()
    setFileToUploadPreview('')
    setFileToUpload(null)
  }

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values, actions) => {
      const file =
        fileToUpload && fileToUpload.files ? fileToUpload.files[0] : null

      if (file) {
        showLoader()

        const data = new FormData()
        data.append('image', file, file.name)
        data.append('message', values.message)
        data.append('siteUrl', decease.site.url)

        postSiteCondolenceImage(data)
          .then((response) => {
            if (response.data.errors.length) {
              handleFormikCustomErrorsForm(actions, response.data.errors)
            } else {
              handleSuccessfulSubmit()
            }
          })
          .catch((error) => {})
          .finally(() => {
            resetForm()
            hideLoader()
          })
      }
    },
  })

  return (
    <Dialog fullWidth={true} onClose={onClose} open={open}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        style={{ width: '100%' }}
        noValidate
      >
        <Grid container direction="column">
          <Grid container direction="row" item xs={12}>
            <Grid item xs={1} />
            <SCharactersLeftContainer item xs={9}>
              {MAX_TITLE_LENGTH - formik.values.message.length} caracteres
              restantes
            </SCharactersLeftContainer>
            <SCloseIconContainer item xs={2}>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </SCloseIconContainer>
          </Grid>

          {fileToUploadPreview && (
            <SImageContainer item xs={12}>
              <img src={fileToUploadPreview} alt="Imagen a compartir" />
            </SImageContainer>
          )}

          <SUploadImageContainer
            item
            xs={12}
            color={decease.site.color}
            style={{ marginTop: 12 }}
          >
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              onChange={onChangeImage}
              ref={(ref) => {
                if (ref) {
                  setFileToUpload(ref)
                }
              }}
              style={{ display: 'none' }}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button color="primary" component="span" endIcon={<ImageIcon />}>
                Subir imagen
              </Button>
            </label>
          </SUploadImageContainer>

          <SMessageContainer item xs={12} style={{ marginTop: 20 }}>
            <TextField
              autoComplete="message"
              error={!!formik.errors.message}
              helperText={formik.errors.message}
              id="message"
              inputProps={{maxLength: MAX_TITLE_LENGTH}}
              label="Título"
              name="message"
              onChange={formik.handleChange}
              style={{ width: '100%' }}
              type="string"
              value={formik.values.message}
              variant="outlined"
            />
          </SMessageContainer>

          <SShareButtonContainer
            item
            xs={12}
            color={decease.site.color}
            style={{ marginTop: 50, marginBottom: 17 }}
          >
            <Button
              color="primary"
              disabled={
                !fileToUploadPreview ||
                formik.values.message.length > MAX_TITLE_LENGTH
              }
              startIcon={<PublishIcon />}
              type="submit"
            >
              Compartir
            </Button>
          </SShareButtonContainer>
        </Grid>
      </form>
    </Dialog>
  )
}

const SCharactersLeftContainer = styled(Grid)`
  align-items: center;
  color: #acacac;
  display: flex;
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 31.92px;
`

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SImageContainer = styled(Grid)`
  text-align: center;

  img {
    max-height: 100%;
    max-width: 100%;
  }
`

const SMessageContainer = styled(Grid)`
  padding-left: 23px;
  padding-right: 23px;
  text-align: center;
`

interface SShareButtonContainerProps {
  color: string
}
const SShareButtonContainer = styled(Grid)<SShareButtonContainerProps>`
  display: flex;
  justify-content: center;

  button {
    color: ${(props: SShareButtonContainerProps) => props.color};
    :hover {
      color: ${(props: SShareButtonContainerProps) => props.color};
    }
  }
`

interface SUploadImageContainerProps {
  color: string
}
const SUploadImageContainer = styled(Grid)<SUploadImageContainerProps>`
  text-align: center;

  label {
    span {
      color: ${(props: SUploadImageContainerProps) => props.color};
      :hover {
        color: ${(props: SUploadImageContainerProps) => props.color};
      }
    }
  }
`

export default AddImageDialog
