import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import routes from '~Routes/routes'
import CookiePolicyContext from '~Contexts/CookiePolicy.context'

const CookiesConfirmationDialog = () => {
  const { accepted, accept } = useContext(CookiePolicyContext)

  const handleClose = () => {
    accept()
  }

  const handleConfirm = () => {
    accept()
  }

  return (
    <Dialog
      open={!accepted}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <SCloseIconContainer item xs={12}>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </SCloseIconContainer>
      <SDialogTitle id="alert-dialog-title">Política de cookies</SDialogTitle>
      <SDialogContent>
        <DialogContentText id="alert-dialog-description">
          Utilizamos cookies propias necesarias para el acceso. Al continuar
          navegando entendemos que acepta nuestra{' '}
          <Link to={routes.cookiePolicy.path}>POLÍTICA DE COOKIES</Link>.
        </DialogContentText>
      </SDialogContent>
      <SDialogActions>
        <Button
          color="primary"
          onClick={handleConfirm}
          size="large"
          style={{ width: '60%' }}
          variant="contained"
        >
          Entendido
        </Button>
      </SDialogActions>
    </Dialog>
  )
}

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SDialogActions = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 56px;
`

const SDialogContent = styled(DialogContent)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  margin-top: 5px;
`

const SDialogTitle = styled(DialogTitle)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  margin-top: 20px;
  text-align: center;
`

export default CookiesConfirmationDialog
