import { useFormik } from 'formik'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Dialog, Grid, IconButton, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PublishIcon from '@material-ui/icons/Publish'

import LoaderContext from '~Contexts/Loader.context'
import { postSiteCondolenceMessage } from '~Services/index'
import { handleFormikCustomErrorsForm } from '~Utils/index'

const MAX_MESSAGE_LENGTH = 300

interface AddCondolenceMessageDialogProps {
  decease: any
  handleSuccessfulSubmit: any
  handleClose: any
  open: any
}
const AddMessageDialog = ({
  decease,
  handleSuccessfulSubmit,
  handleClose,
  open,
}: AddCondolenceMessageDialogProps) => {
  const { hideLoader, showLoader } = useContext(LoaderContext)

  const onClose = () => {
    formik.resetForm()
    handleClose()
  }

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: (values, actions) => {
      showLoader()

      postSiteCondolenceMessage(values.message, decease.site.url)
        .then((response) => {
          if (response.data.errors.length) {
            handleFormikCustomErrorsForm(actions, response.data.errors)
          } else {
            handleSuccessfulSubmit()
          }
        })
        .catch((error) => {})
        .finally(() => {
          formik.resetForm()
          hideLoader()
        })
    },
  })

  return (
    <Dialog fullWidth={true} onClose={onClose} open={open}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        style={{ width: '100%' }}
        noValidate
      >
        <Grid container direction="row">
          <Grid container direction="row" item xs={12}>
            <Grid item xs={1} />
            <SCharactersLeftContainer item xs={9}>
              {MAX_MESSAGE_LENGTH - formik.values.message.length} caracteres
              restantes
            </SCharactersLeftContainer>
            <SCloseIconContainer item xs={2}>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </SCloseIconContainer>
          </Grid>
          <Grid
            container
            direction="row"
            item
            xs={12}
            style={{ marginTop: 20 }}
          >
            <Grid item xs={1} />
            <Grid item xs={10}>
              <TextField
                autoComplete="message"
                error={!!formik.errors.message}
                helperText={formik.errors.message}
                id="message"
                inputProps={{maxLength: MAX_MESSAGE_LENGTH}}
                label="Escriba aquí sus condolencias"
                multiline={true}
                name="message"
                onChange={formik.handleChange}
                required
                rows={6}
                style={{ width: '100%' }}
                type="string"
                value={formik.values.message}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <SShareButtonContainer
            item
            xs={12}
            color={decease.site.color}
            style={{ marginTop: 27, marginBottom: 17 }}
          >
            <Button
              color="primary"
              disabled={
                !formik.values.message ||
                formik.values.message.length > MAX_MESSAGE_LENGTH
              }
              type="submit"
              startIcon={<PublishIcon />}
            >
              Compartir
            </Button>
          </SShareButtonContainer>
        </Grid>
      </form>
    </Dialog>
  )
}

const SCharactersLeftContainer = styled(Grid)`
  align-items: center;
  color: #acacac;
  display: flex;
  font-family: 'Roboto Light';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 31.92px;
`

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

interface SShareButtonContainerProps {
  color: string
}
const SShareButtonContainer = styled(Grid)<SShareButtonContainerProps>`
  display: flex;
  justify-content: center;

  button {
    color: ${(props: SShareButtonContainerProps) => props.color};
    :hover {
      color: ${(props: SShareButtonContainerProps) => props.color};
    }
  }
`

export default AddMessageDialog
