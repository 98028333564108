import { pathOr } from 'ramda'
import React, { useCallback } from 'react'
import 'moment/locale/es'

import CeremonyRender from '~Screens/Edition/components/CeremonyRender'
import CeremonyType from '~Types/Ceremony.type'

interface CeremonyProps {
  formik: any
  index: any
}
const Ceremony = ({ formik, index }: CeremonyProps) => {
  // const formik1 = useFormikContext<any>() // errors types not working

  const removeThisCeremony = useCallback(
    () => {
      const ceremonies = formik.values.decease.site.ceremonies
      ceremonies.splice(index, 1)
      formik.setFieldValue('decease.site.ceremonies', ceremonies)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.decease.site.ceremonies.length]
  )

  const updateCeremonyValues = useCallback(
    (index, field, value) => {
      const ceremonies = formik.values.decease.site.ceremonies
      ceremonies[index][field] = value
      formik.setFieldValue('decease.site.ceremonies', ceremonies)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.decease.site.ceremonies[index]]
  )

  const ceremonyValues = pathOr(
    null,
    ['values', 'decease', 'site', 'ceremonies', index],
    formik
  )
  if (!ceremonyValues) {
    return <></>
  }

  const addressError = pathOr(
    '',
    ['errors', 'decease', 'site', 'ceremonies', index, 'address'],
    formik
  )
  const addressValue = pathOr('', ['address'], ceremonyValues)

  const addressAliasesError = pathOr(
    '',
    ['errors', 'decease', 'site', 'ceremonies', index, 'addressAliases'],
    formik
  )
  const addressAliasesValue = pathOr('', ['addressAliases'], ceremonyValues)

  const dateValue = pathOr('', ['date'], ceremonyValues)
  const datetimeAtValue = pathOr('', ['datetimeAt'], ceremonyValues)
  const datetimeToValue = pathOr('', ['datetimeTo'], ceremonyValues)

  const extraInformationError = pathOr(
    '',
    ['errors', 'decease', 'site', 'ceremonies', index, 'extraInformation'],
    formik
  )
  const extraInformationValue = pathOr('', ['extraInformation'], ceremonyValues)

  const titleError = pathOr(
    '',
    ['errors', 'decease', 'site', 'ceremonies', index, 'title'],
    formik
  )
  const titleValue = pathOr('', ['title'], ceremonyValues)

  const type = pathOr(
    CeremonyType.WAKE,
    ['values', 'decease', 'site', 'ceremonies', index, 'type'],
    formik
  )

  return (
    <CeremonyRender
      addressAliasesError={addressAliasesError}
      addressAliasesValue={addressAliasesValue}
      addressError={addressError}
      addressValue={addressValue}
      dateValue={dateValue}
      datetimeAtValue={datetimeAtValue}
      datetimeToValue={datetimeToValue}
      extraInformationError={extraInformationError}
      extraInformationValue={extraInformationValue}
      handleChange={formik.handleChange}
      index={index}
      removeThisCeremony={removeThisCeremony}
      setFieldValue={formik.setFieldValue}
      titleError={titleError}
      titleValue={titleValue}
      type={type}
      updateCeremonyValues={updateCeremonyValues}
    />
  )
}

export default Ceremony
