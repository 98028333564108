import { useFormik } from 'formik'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Dialog, Grid, IconButton, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Rating from '@material-ui/lab/Rating'

import RobinsonThird from '~Components/RobinsonThird'
import LoaderContext from '~Contexts/Loader.context'
import UserConfigContext from '~Contexts/UserConfig.context'
import { postSiteReview } from '~Services/index'
import { handleFormikCustomErrorsForm } from '~Utils/index'

interface ReviewDialogProps {
  decease: any
  handleClose: any
  handleSuccessfulSubmitReviewDialog: any
  open: any
}
const ReviewDialog = ({
  decease,
  handleClose,
  handleSuccessfulSubmitReviewDialog,
  open,
}: ReviewDialogProps) => {
  const { hideLoader, showLoader } = useContext(LoaderContext)
  const { setUserConfig, visitor } = useContext(UserConfigContext)

  const formik = useFormik({
    initialValues: {
      robinsonThird: false,
      opinion: '',
      rating: 0,
    },
    onSubmit: (values, actions) => {
      showLoader()

      postSiteReview(
        values.opinion,
        values.rating,
        values.robinsonThird,
        decease.site.url
      )
        .then((response) => {
          if (response.data.errors.length) {
            handleFormikCustomErrorsForm(actions, response.data.errors)
          } else {
            setUserConfig(response.data.data.userConfig)
            handleSuccessfulSubmitReviewDialog()
          }
        })
        .catch((error) => {})
        .finally(() => {
          hideLoader()
        })
    },
  })

  return (
    <Dialog fullWidth={true} maxWidth="sm" onClose={handleClose} open={open}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        style={{ width: '100%' }}
        noValidate
      >
        <Grid container direction="column">
          <SCloseIconContainer item xs={12}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </SCloseIconContainer>

          <STitleContainer item xs={12} style={{ marginTop: 35 }}>
            Ayúdenos a mejorar.
          </STitleContainer>

          <SRatingContainer
            item
            xs={12}
            color={decease.site.color}
            style={{ marginTop: 45 }}
          >
            <Rating
              name="rating"
              precision={0.5}
              size="large"
              onChange={(event, value) => {
                formik.setFieldValue('rating', value)
              }}
              value={formik.values.rating}
            />
          </SRatingContainer>

          <SOpinionContainer item xs={12} style={{ marginTop: 45 }}>
            <TextField
              autoComplete="opinion"
              error={!!formik.errors.opinion}
              helperText={formik.errors.opinion ? formik.errors.opinion : ''}
              id="opinion"
              label="Escriba aquí su opinión."
              multiline={true}
              name="opinion"
              onChange={formik.handleChange}
              rows={8}
              style={{ width: '100%' }}
              type="string"
              value={formik.values.opinion}
              variant="outlined"
            />
          </SOpinionContainer>

          {visitor && !visitor.robinsonThird && (
            <SRobinsonThirdContainer item xs={12} style={{ marginTop: 24 }}>
              <RobinsonThird
                isChecked={formik.values.robinsonThird}
                onChange={formik.handleChange}
              />
            </SRobinsonThirdContainer>
          )}

          <SSentButtonContainer
            item
            xs={12}
            color={decease.site.color}
            style={{ marginTop: 35, marginBottom: 17 }}
          >
            <Button color="primary" type="submit">
              Enviar
            </Button>
          </SSentButtonContainer>
        </Grid>
      </form>
    </Dialog>
  )
}

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SOpinionContainer = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
`

interface SRatingContainerProps {
  color: string
}
const SRatingContainer = styled(Grid)<SRatingContainerProps>`
  text-align: center;

  .MuiRating-icon {
    margin-left: 5px;
    margin-right: 5px;
  }

  .MuiRating-iconFilled {
    color: ${(props: SSentButtonContainerProps) => props.color};
  }
`

const SRobinsonThirdContainer = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
`

interface SSentButtonContainerProps {
  color: string
}
const SSentButtonContainer = styled(Grid)<SSentButtonContainerProps>`
  display: flex;
  justify-content: center;

  button {
    color: ${(props: SSentButtonContainerProps) => props.color};
    :hover {
      color: ${(props: SSentButtonContainerProps) => props.color};
    }
  }
`

const STitleContainer = styled(Grid)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  text-align: center;
`

export default ReviewDialog
