import { RouteProps } from 'react-router-dom'

import AccessManager from '~Screens/Access/Manager'
import AccessNewManager from '~Screens/Access/NewManager'
import Visitor from '~Screens/Access/Visitor'
import CookiesPolicy from '~Screens/CookiesPolicy'
import Edition from '~Screens/Edition'
import PageNotFound from '~Screens/PageNotFound'
import PrivacyPolicy from '~Screens/PrivacyPolicy'
import View from '~Screens/View'

export interface RouteType extends RouteProps {
  name: string
  path: string
}

const createRoutes = <T extends object>(
  item: Record<keyof T, RouteType>
): Record<keyof T, RouteType> => item

export const defaultSitePath = '/en-memoria-de/:name/:code'

const routes = createRoutes({
  accessManager: {
    component: AccessManager,
    name: 'Manager',
    path: '/en-memoria-de/:name/:code/acceso-gestor',
  },
  accessNewManager: {
    component: AccessNewManager,
    name: 'New manager',
    path: '/en-memoria-de/:name/:code/acceso-nuevo-gestor',
  },
  accessVisitor: {
    component: Visitor,
    name: 'Allegado',
    path: '/en-memoria-de/:name/:code/acceso-allegado',
  },
  cookiePolicy: {
    component: CookiesPolicy,
    name: 'Política de cookies',
    path: '/politica-de-cookies',
  },
  edition: {
    component: Edition,
    name: 'Edition',
    path: '/en-memoria-de/:name/:code/edicion',
  },
  index: {
    component: View,
    name: 'Vista',
    path: '/en-memoria-de/:name/:code',
  },
  pageNotFound: {
    component: PageNotFound,
    name: 'Página no encontrada',
    path: '/pagina-no-encontrada',
  },
  privacyPolicy: {
    component: PrivacyPolicy,
    name: 'Política de privacidad',
    path: '/politica-de-privacidad',
  },
  view: {
    component: View,
    name: 'Vista',
    path: '/en-memoria-de/:name/:code/vista',
  },
})

export default routes
