import { pathOr } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { Grid, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

import Ceremony from '~Screens/Edition/components/Ceremony'

interface CeremoniesProps {
  formik: any
  width: Breakpoint
}
const Ceremonies = ({ formik, width }: CeremoniesProps) => {
  const ceremonies = pathOr(
    [],
    ['values', 'decease', 'site', 'ceremonies'],
    formik
  )

  return (
    <>
      {ceremonies.length ? (
        <SSectionContainer
          container
          direction="row"
          justify="center"
          spacing={width === 'xs' || width === 'sm' ? 0 : 2}
        >
          {ceremonies.length
            ? ceremonies.map((data: any, key: number) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={data.type}
                    style={{ marginTop: 16 }}
                  >
                    <Ceremony formik={formik} index={key} />
                  </Grid>
                )
              })
            : ''}
        </SSectionContainer>
      ) : (
        ''
      )}
    </>
  )
}

const SSectionContainer = styled(Grid)`
  margin-top: 16px;
`

export default withWidth()(Ceremonies)
