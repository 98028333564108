import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  Avatar,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ImageIcon from '@material-ui/icons/Image'

import LoaderContext from '~Contexts/Loader.context'
import { postSiteAvatar } from '~Services/index'
import CardTitle from '~Screens/Edition/components/CardTitle'
import ColorThemeSelector from '~Screens/Edition/components/ColorThemeSelector'

interface HeadboardRenderProps {
  avatarUrl: string | null
  setFieldValue: any
  siteColor: string | null
  siteUrl: string
}

const HeadboardRender = ({
  avatarUrl,
  setFieldValue,
  siteColor,
  siteUrl,
}: HeadboardRenderProps) => {
  const { showLoader, hideLoader } = useContext(LoaderContext)
  const [avatarUrlHash, setAvatarUrlHash] = React.useState(Date.now())
  const [currentAvatarUrl, setCurrentAvatarUrl] = React.useState(
    avatarUrl ? avatarUrl : ''
  )
  const [fileToUpload, setFileToUpload] = React.useState<HTMLInputElement>()

  const onChange = () => {
    const file =
      fileToUpload && fileToUpload.files ? fileToUpload.files[0] : null

    if (file) {
      showLoader()

      const data = new FormData()
      data.append('image', file, file.name)
      data.append('siteUrl', siteUrl)

      postSiteAvatar(data)
        .then((response) => {
          if (response.data.errors.length) {
            const errors: any[] = response.data.errors
            for (const error of errors) {
              if (error.param && error.msg) {
              }
            }
          } else {
            setCurrentAvatarUrl(response.data.avatarUrl)
            setAvatarUrlHash(Date.now())
          }
        })
        .catch((error) => {})
        .finally(() => {
          hideLoader()
        })
    }
  }

  return (
    <SCard container direction="column">
      <ExpansionPanel defaultExpanded={true}>
        <SExpansionPanelSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <CardTitle>Cabecera</CardTitle>
        </SExpansionPanelSummary>
        <SExpansionPanelDetails>
          <Grid
            container
            direction="column"
            justify="center"
            style={{ marginTop: 10 }}
          >
            <ColorThemeSelector
              setFieldValue={setFieldValue}
              siteColor={siteColor}
            />
          </Grid>

          {currentAvatarUrl && avatarUrlHash && (
            <SAvatarContainer item xs={12} style={{ marginTop: 32 }}>
              <SAvatar
                alt="Foto del fallecido"
                src={`${currentAvatarUrl}?${avatarUrlHash}`}
              />
            </SAvatarContainer>
          )}

          <UploadImageContainer item xs={12} style={{ marginTop: 24 }}>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              onChange={onChange}
              ref={(ref) => {
                if (ref) {
                  setFileToUpload(ref)
                }
              }}
              style={{ display: 'none' }}
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button color="primary" component="span" endIcon={<ImageIcon />}>
                Subir imagen
              </Button>
            </label>
          </UploadImageContainer>
        </SExpansionPanelDetails>
      </ExpansionPanel>
    </SCard>
  )
}

const SAvatar = styled(Avatar)`
  height: 161px;
  width: 161px;
`

const SAvatarContainer = styled(Grid)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
`

const SExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
`

const SExpansionPanelSummary = styled(ExpansionPanelSummary)`
  .MuiExpansionPanelSummary-content {
    justify-content: center;
  }

  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.87);
  }
`

const UploadImageContainer = styled(Grid)`
  text-align: center;
`

export default React.memo(HeadboardRender)
