import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import ColorBox from '~Screens/Edition/components/ColorBox'

interface ColorThemeSelectorProps {
  setFieldValue: any
  siteColor: string | null
}

enum Colors {
  BLUE = '#ACDAF4',
  GREEN = '#C3E1AF',
  ORANGE = '#F2BA74',
  LILAC = '#BCAFE1',
}

const ColorThemeSelector = ({
  setFieldValue,
  siteColor,
}: ColorThemeSelectorProps) => {
  const colorsValues = Object.values(Colors)

  const onClick = (newColor: string) => {
    if (siteColor !== newColor) {
      setFieldValue('decease.site.color', newColor)
    }
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        Escoger color de fondo
      </Grid>
      <SColorThemeSelectorContainer
        container
        justify="center"
        spacing={3}
        style={{ marginTop: 4 }}
      >
        {colorsValues.map((data: any) => {
          const currentColor = siteColor ? siteColor : Colors.BLUE
          const isSelected = currentColor === data

          return (
            <Grid item key={data}>
              <ColorBox
                color={data}
                isSelected={isSelected}
                onClick={onClick}
              />
            </Grid>
          )
        })}
      </SColorThemeSelectorContainer>
    </Grid>
  )
}

const SColorThemeSelectorContainer = styled(Grid)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
`

export default ColorThemeSelector
