import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

import { useWidth } from '~Hooks/useWidth'

const PageNotFound = (props: RouteComponentProps) => {
  const width = useWidth()

  return (
    <SGridContainer
      container
      direction="row"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={2} />
      <Grid item xs={8}>
        <STitle width={width}>
          Lo sentimos, esta página no esta disponible
        </STitle>
        <SSubTitle width={width} style={{ marginTop: 30 }}>
          la página que buscabas no se pudo encontrar
        </SSubTitle>
      </Grid>
      <Grid item xs={2} />
    </SGridContainer>
  )
}

const SGridContainer = styled(Grid)`
  flex: 1;
`

interface SSubTitleProps {
  width: string
}
const SSubTitle = styled.div<SSubTitleProps>`
  color: rgba(0, 0, 0, 0.54);
  font-family: 'Roboto Light';
  font-size: ${(props: SSubTitleProps) => {
    if (props.width === 'xs' || props.width === 'sm') {
      return 20
    }
    return 40
  }}px;
  font-weight: 300;
  letter-spacing: 0.08px;
  line-height: ${(props: SSubTitleProps) => {
    if (props.width === 'xs' || props.width === 'sm') {
      return 36
    }
    return 56
  }}px;
  text-align: center;
`

interface STitleProps {
  width: string
}
const STitle = styled.div<STitleProps>`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: ${(props: SSubTitleProps) => {
    if (props.width === 'xs' || props.width === 'sm') {
      return 30
    }
    return 56
  }}px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: ${(props: SSubTitleProps) => {
    if (props.width === 'xs' || props.width === 'sm') {
      return 42
    }
    return 72
  }}px;
  text-align: center;
`

export default PageNotFound
