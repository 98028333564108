import { pathOr } from 'ramda'
import React from 'react'
import ObituaryRender from '~Screens/Edition/components/ObituaryRender'


interface ObituaryProps {
  formik: any
}
const Obituary = ({ formik }: ObituaryProps) => {
  const decease = pathOr(null, ['values', 'decease'], formik)
  if (!decease) {
    return <></>
  }

  const firstnameError = pathOr(
    '',
    ['errors', 'decease', 'deceased', 'firstname'],
    formik
  )
  const firstnameValue = pathOr(
    '',
    ['values', 'decease', 'deceased', 'firstname'],
    formik
  )

  const lastnameError = pathOr(
    '',
    ['errors', 'decease', 'deceased', 'lastname'],
    formik
  )
  const lastnameValue = pathOr(
    '',
    ['values', 'decease', 'deceased', 'lastname'],
    formik
  )

  const titleError = pathOr('', ['errors', 'decease', 'site', 'title'], formik)
  const titleValue = pathOr('', ['values', 'decease', 'site', 'title'], formik)

  return (
    <ObituaryRender
      firstnameError={firstnameError}
      firstnameValue={firstnameValue}
      lastnameError={lastnameError}
      lastnameValue={lastnameValue}
      titleError={titleError}
      titleValue={titleValue}
      handleChange={formik.handleChange}
    />
  )
}

export default Obituary
