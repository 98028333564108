import React from 'react'
import styled from 'styled-components'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import CardTitle from '~Screens/Edition/components/CardTitle'

interface ObituaryRenderProps {
  firstnameError: string
  firstnameValue: string
  lastnameError: string
  lastnameValue: string
  titleError: string
  titleValue: string
  handleChange: any
}

const ObituaryRender = ({
  firstnameError,
  firstnameValue,
  lastnameError,
  lastnameValue,
  titleError,
  titleValue,
  handleChange,
}: ObituaryRenderProps) => {
  return (
    <SCard container direction="column">
      <ExpansionPanel defaultExpanded={true}>
        <SExpansionPanelSummary
          aria-controls="panel1a-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <CardTitle>Obituario</CardTitle>
        </SExpansionPanelSummary>
        <SExpansionPanelDetails>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <TextField
              autoComplete="firstname"
              error={!!firstnameError}
              helperText={firstnameError}
              id="firstname"
              label="Nombre"
              name="firstname"
              onChange={handleChange('decease.deceased.firstname')}
              required
              style={{ width: '100%' }}
              type="string"
              value={firstnameValue}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 26 }}>
            <TextField
              autoComplete="lastname"
              error={!!lastnameError}
              helperText={lastnameError}
              id="lastname"
              label="Apellidos"
              name="lastname"
              onChange={handleChange('decease.deceased.lastname')}
              required
              style={{ width: '100%' }}
              type="string"
              value={lastnameValue}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: 26 }}>
            <TextField
              autoComplete="title"
              error={!!titleError}
              helperText={titleError}
              id="title"
              label="Texto"
              multiline={true}
              name="title"
              onChange={handleChange('decease.site.title')}
              required
              rows={3}
              style={{ width: '100%' }}
              type="string"
              value={titleValue}
              variant="outlined"
            />
          </Grid>
        </SExpansionPanelDetails>
      </ExpansionPanel>
    </SCard>
  )
}

const SCard = styled(Grid)`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
`

const SExpansionPanelDetails = styled(ExpansionPanelDetails)`
  flex-direction: column;
`

const SExpansionPanelSummary = styled(ExpansionPanelSummary)`
  .MuiExpansionPanelSummary-content {
    justify-content: center;
  }

  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.87);
  }
`

export default React.memo(ObituaryRender)
