import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

interface ConfirmationDialogProps {
  confirmationButtonTitle: string
  description: string
  handleClose: () => void
  handleConfirm: () => void
  open: boolean
  title: string
}

const ConfirmationDialog = ({
  confirmationButtonTitle,
  description,
  handleClose,
  handleConfirm,
  open,
  title,
}: ConfirmationDialogProps) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <SCloseIconContainer item xs={12}>
      <IconButton aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </SCloseIconContainer>
    <SDialogTitle id="alert-dialog-title">{title}</SDialogTitle>
    <SDialogContent>
      <DialogContentText id="alert-dialog-description">
        {description}
      </DialogContentText>
    </SDialogContent>
    <SDialogActions>
      <Button
        color="primary"
        onClick={handleConfirm}
        size="large"
        style={{ width: '60%' }}
        variant="text"
      >
        {confirmationButtonTitle}
      </Button>
    </SDialogActions>
  </Dialog>
)

const SCloseIconContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`

const SDialogActions = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 12px;
`

const SDialogContent = styled(DialogContent)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  margin-top: 5px;
  text-align: center;
`

const SDialogTitle = styled(DialogTitle)`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.07px;
  line-height: 32px;
  margin-top: 30px;
  text-align: center;
`

export default ConfirmationDialog
